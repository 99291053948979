import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getIsAPILoggedIn,
  getLoginData,
  findActivePanelsByOrgId,
  getStates,
  getSurveyFilingStatuses,
  saveAnswers,
  getRegions,
} from "./Survey.service";

const initialState = {
  loggedInUserData: null,
  surveyPanels: [],
  selectedPanelId: null,
  homeSurveyData: {
    selectedSurveyId: null,
    selectedSurveyName: null,
    selectedSurveyPanelId: null,
    previewSurveyId: null,
  },
  batchUpdateData: {
    batchUpdateCurrentView: 1,
    updateFilingStatus: "yes",
    selectedStates: [],
    selectedListPmas: [],
    selectedTextPmas: "",
    selectedDataFilters: "none",
    selectedSurveyFilingIds: [],
    updatedSurveyFilingIds: [],
    selectedFields: [],
    selectedQuesGroups: [],
    validationData: [],
  },
  states: [],
  regions: [],
  surveyFilingStatuses: [],
  filterFormData: {},
  //panel member
  selectedPanelMemberData: {},
  selectedCreatePanelId: null,
  importCurrentView: 1,
  importPastBatchResult: null,
  selectedBatchId: null,
  surveyBuilderData: {
    surveyBuilderCurrentView: {
      viewIndex: 1,
      source: "",
      showDataFilterRule: false,
      datafilterRecord: {},
    },
    surveyData: {},
    sidebarLoading: false,
    sidebarRefreshFlag: false,
    selectedSec: {},
    selectedPage: {},
    selectedQues: {},
    selectedQuesGrp: {},
    selectedRuleField: {},
    histSelectedQues: {},
    savedQuesGrp: null,
    triggerMatrixGrp: false,
    savedQues: null,
    savedField: null,
    savedComField: {},
    selectedField: null,
    EditQuesGrpId: null,
    EditQuesId: null,
    EditFieldId: null,
    quesEditSource: null,
    addQuesToGrp: false,
  },
  selectedOrgs: {},
};

export const checkLoginStatus = createAsyncThunk("survay/login", async () => {
  try {
    const response = await getIsAPILoggedIn();
    // console.log("the login status is: ", response);
    return response?.data;
  } catch (error) {
    throw error;
  }
});

export const getLoginAuthenticated = createAsyncThunk(
  "survay/login/auth",
  async () => {
    try {
      const response = await getLoginData();
      // console.log("the login data status: ", response);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchPanelsByOrgId = createAsyncThunk(
  "Panels/OrgId",
  async (organizationId) => {
    try {
      const response = await findActivePanelsByOrgId(organizationId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSurveyFilingStatuses = createAsyncThunk(
  "Panels/SurveyFilingStatuses",
  async () => {
    try {
      const response = await getSurveyFilingStatuses();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchStates = createAsyncThunk("Panels/States", async () => {
  try {
    const response = await getStates();
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const fetchRegions = createAsyncThunk("Panels/Regions", async () => {
  try {
    const response = await getRegions();
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const saveAnswerOptions = createAsyncThunk(
  "survey/save",
  async (payload) => {
    try {
      const response = await saveAnswers(payload);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    setSurveyBuilderCurrentView: (state, action) => {
      const { viewIndex, source, showDataFilterRule, datafilterRecord } =
        action.payload;
      state.surveyBuilderData.surveyBuilderCurrentView.viewIndex = viewIndex;
      state.surveyBuilderData.surveyBuilderCurrentView.source = source;
      state.surveyBuilderData.surveyBuilderCurrentView.showDataFilterRule =
        showDataFilterRule;
      state.surveyBuilderData.surveyBuilderCurrentView.datafilterRecord =
        datafilterRecord;
    },
    setSurveyBuilderSurvey: (state, action) => {
      state.surveyBuilderData.surveyData = action.payload;
    },
    triggerSidebarRefresh: (state) => {
      state.surveyBuilderData.sidebarRefreshFlag =
        !state.surveyBuilderData.sidebarRefreshFlag;
    },
    setSidebarLoading: (state, action) => {
      state.surveyBuilderData.sidebarLoading = action.payload;
    },
    setSelectedSec: (state, action) => {
      state.surveyBuilderData.selectedSec = action.payload;
    },
    setSelectedPage: (state, action) => {
      state.surveyBuilderData.selectedPage = action.payload;
    },
    setSelectedQues: (state, action) => {
      state.surveyBuilderData.selectedQues = action.payload;
    },
    setSelectedQuesGrp: (state, action) => {
      state.surveyBuilderData.selectedQuesGrp = action.payload;
    },
    setSelectedRuleField: (state, action) => {
      state.surveyBuilderData.selectedRuleField = action.payload;
    },
    selHistSelectedQues: (state, action) => {
      state.surveyBuilderData.histSelectedQues = action.payload;
    },
    setSavedQuesGrp: (state, action) => {
      state.surveyBuilderData.savedQuesGrp = action.payload;
    },
    setTriggerMatrixGrp: (state, action) => {
      state.surveyBuilderData.triggerMatrixGrp = action.payload;
    },
    setSavedQues: (state, action) => {
      state.surveyBuilderData.savedQues = action.payload;
    },
    setSavedField: (state, action) => {
      state.surveyBuilderData.savedField = action.payload;
    },
    setSavedComField: (state, action) => {
      state.surveyBuilderData.savedComField = action.payload;
    },
    setSelectedField: (state, action) => {
      state.surveyBuilderData.selectedField = action.payload;
    },
    setEditQuesGrpId: (state, action) => {
      state.surveyBuilderData.EditQuesGrpId = action.payload;
    },
    setEditQuesId: (state, action) => {
      state.surveyBuilderData.EditQuesId = action.payload;
    },
    setEditFieldId: (state, action) => {
      state.surveyBuilderData.EditFieldId = action.payload;
    },
    setQuesEditSource: (state, action) => {
      state.surveyBuilderData.quesEditSource = action.payload;
    },
    setAddQuesToGrp: (state, action) => {
      state.surveyBuilderData.addQuesToGrp = action.payload;
    },
    resetSurveyBuilderData(state) {
      state.surveyBuilderData = initialState.surveyBuilderData;
    },
    setSelectedPanelId(state, action) {
      state.selectedPanelId = action.payload;
    },
    setSelectedSurveyId(state, action) {
      state.homeSurveyData.selectedSurveyId = action.payload;
    },
    setSelectedSurveyName(state, action) {
      state.homeSurveyData.selectedSurveyName = action.payload;
    },
    setSelectedSurveyPanelId(state, action) {
      state.homeSurveyData.selectedSurveyPanelId = action.payload;
    },
    setPreviewSurveyId(state, action) {
      state.homeSurveyData.previewSurveyId = action.payload;
    },
    resetHomeSurveyData(state) {
      state.homeSurveyData = initialState.homeSurveyData;
    },
    setBatchUpdateCurrentView: (state, action) => {
      state.batchUpdateData.batchUpdateCurrentView = action.payload;
    },
    setUpdateFilingStatus(state, action) {
      state.batchUpdateData.updateFilingStatus = action.payload;
    },
    setSelectedStates(state, action) {
      state.batchUpdateData.selectedStates = action.payload;
    },
    setSelectedListPmas(state, action) {
      state.batchUpdateData.selectedListPmas = action.payload;
    },
    setSelectedTextPmas(state, action) {
      state.batchUpdateData.selectedTextPmas = action.payload;
    },
    setSelectedDataFilters(state, action) {
      state.batchUpdateData.selectedDataFilters = action.payload;
    },
    setSelectedSurveyFilingIds(state, action) {
      state.batchUpdateData.selectedSurveyFilingIds = action.payload;
    },
    setUpdatedSurveyFilingIds(state, action) {
      state.batchUpdateData.updatedSurveyFilingIds = action.payload;
    },
    setSelectedQuesFields(state, action) {
      state.batchUpdateData.selectedFields = action.payload;
    },
    setSelectedQuesGroups(state, action) {
      state.batchUpdateData.selectedQuesGroups = action.payload;
    },
    setValidationData(state, action) {
      state.batchUpdateData.validationData = action.payload;
    },
    resetBatchUpdateData(state) {
      state.batchUpdateData = initialState.batchUpdateData;
    },
    setFilterFormData(state, action) {
      state.filterFormData = action.payload;
    },
    //panel member
    setSelectedCreatePanelId(state, action) {
      state.selectedCreatePanelId = action.payload;
    },
    setSelectedPanelMemberData(state, action) {
      state.selectedPanelMemberData = action.payload;
    },
    setImportCurrentView: (state, action) => {
      state.importCurrentView = action.payload;
    },
    setImportPastBatchResult: (state, action) => {
      state.importPastBatchResult = action.payload;
    },
    setSelectedBatchId: (state, action) => {
      state.selectedBatchId = action.payload;
    },
    setSelectedOrgs(state, action) {
      state.selectedOrgs = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoginAuthenticated.fulfilled, (state, action) => {
        state.loggedInUserData = action.payload;
      })
      .addCase(fetchPanelsByOrgId.fulfilled, (state, action) => {
        state.surveyPanels = action.payload;
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.states = action.payload;
      })
      .addCase(fetchRegions.fulfilled, (state, action) => {
        state.regions = action.payload;
      })
      .addCase(fetchSurveyFilingStatuses.fulfilled, (state, action) => {
        state.surveyFilingStatuses = action.payload;
      });
  },
});

export const surveyReducer = surveySlice.reducer;
export const {
  setSelectedPanelId,
  setSelectedSurveyId,
  setSelectedSurveyName,
  setSelectedSurveyPanelId,
  setPreviewSurveyId,
  resetHomeSurveyData,
  setUpdateFilingStatus,
  setSelectedStates,
  setSelectedListPmas,
  setSelectedTextPmas,
  setSelectedDataFilters,
  setSelectedSurveyFilingIds,
  setUpdatedSurveyFilingIds,
  setSelectedQuesFields,
  setSelectedQuesGroups,
  setValidationData,
  resetBatchUpdateData,
  setFilterFormData,
  setBatchUpdateCurrentView,
  setImportCurrentView,
  setImportPastBatchResult,
  setSelectedBatchId,
  setSurveyBuilderSurvey,
  triggerSidebarRefresh,
  setSidebarLoading,
  setSelectedSec,
  setSelectedPage,
  setSelectedQues,
  setSelectedQuesGrp,
  setSelectedRuleField,
  setSavedQuesGrp,
  setTriggerMatrixGrp,
  setSavedQues,
  setSavedField,
  setSavedComField,
  setSelectedField,
  setEditQuesGrpId,
  setEditQuesId,
  setEditFieldId,
  setQuesEditSource,
  setAddQuesToGrp,
  setSurveyBuilderCurrentView,
  resetSurveyBuilderData,
  selHistSelectedQues,
  setSelectedOrgs,
} = surveySlice.actions;
//panel member
export const { setSelectedCreatePanelId, setSelectedPanelMemberData } =
  surveySlice.actions;
