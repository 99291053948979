import { useEffect, useMemo, useState } from "react";
import { Modal, Typography, Button, Grid } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import RecyclingOutlinedIcon from "@mui/icons-material/RecyclingOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import { FormControlLabel, Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  checkLoginStatus,
  fetchStates,
  fetchSurveyFilingStatuses,
  getLoginAuthenticated,
  resetSurveyBuilderData,
  resetBatchUpdateData,
  resetHomeSurveyData,
  setSelectedPanelId,
  setSelectedSurveyId,
  setSelectedSurveyName,
  setSelectedSurveyPanelId,
  fetchRegions,
  setPreviewSurveyId,
} from "../Survey/Survey.slice";
import { useDispatch, useSelector } from "react-redux";
import {
  BASE_URL,
  formatDate,
  dateInPast,
  htmlParser,
  getTextFromHTML,
} from "../../Utility/constants";
import {
  getPersonDisplaySettings,
  getSurveyByOrg,
  setPersonDisplaySettings,
  getSurveyByPersonId,
  getOtherSurveysByOrgIdPersonId,
  getSurveyFilingCount,
  copyBuilderSurvey,
  getRespondentByUsername,
  getSurveyFiling,
  createSurveyFiling,
  updateSurveyOrganization,
  deleteSurveyFiling,
  deleteSelectedSurvey,
  deleteSurveyData,
} from "../Survey/Survey.service";
import Tooltip from "../../components/Common/Tooltip";
import theme from "../../theme";
import LoadingText from "../../components/Common/LoadingText";
import SurveyInfo from "./SurveyInfo";
import ConfirmDialog from "../../components/Common/ConfirmDialog";
import StateSelectModal from "./StateSelectModal";
import { USER_ROLES } from "../../Utility/constants";

const Homepage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [surveys, setSurveys] = useState([]);
  const [showMySurveys, setShowMySurveys] = useState(false);
  const [showOtherSurveys, setShowOtherSurveys] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [openSurveyInfoModal, setOpenSurveyInfoModal] = useState(false);
  const [statusInfo, setStatusInfo] = useState({});
  const [showLoading, setShowLoading] = useState(true);
  const [confirmTitle, setConfirmTitle] = useState(null);
  const [confirmId, setConfirmId] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const [confirmDataId, setConfirmDataId] = useState(0);
  const [confirmDeleteDataOpen, setConfirmDeleteDataOpen] = useState(false);
  const initialColumns = {
    surveyName: true,
    status: true,
    responses: true,
    owner: true,
    createdDate: true,
    lastUpdated: true,
    surveyInfo: true,
    modifySurvey: true,
    copySurvey: true,
    preview: true,
    panelMgr: true,
    fileMgr: true,
    processSurvey: true,
    batchUpdate: true,
    reports: true,
    blankSurvey: true,
    delete: true,
    deletesurveydata: true,
  };
  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );

  const orgName = useSelector(
    (state) =>
      state?.survey?.loggedInUserData?.authorizedUser?.organization?.name
  );

  const orgId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.organization?.id
  );

  const personId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.id
  );
  const stateCodeId = useSelector(
    (state) => state?.survey?.loggedInUserData?.stateCode?.id
  );
  const [openStateModal, setOpenStateModal] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [surveyFilingId, setSurveyFilingId] = useState(null);
  const [surveyRespondent, setSurveyRespondent] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const roleName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.role?.name
  );
  const orgType = useSelector(
    (state) =>
      state?.survey?.loggedInUserData?.authorizedUser?.organization?.orgType
        ?.name
  );
  const isSuperUser =
    roleName === USER_ROLES.BUILDER_SUPER_USER_ROLENAME ? true : false;

  const isBuilderAdminUser =
    roleName === USER_ROLES.BUILDER_ADMIN_ROLENAME ? true : false;

  const isOrgTypeMarketResearch =
    orgType === "Market Research" || orgType === "Market Research Affiliate"
      ? true
      : false;
  const roleMessage = "This feature is not available to your user role";
  const isBuilderGroupUser =
    roleName === USER_ROLES.BUILDER_AHA_GROUP_USER_ROLENAME ? true : false;

  const isStakeholderUser =
    roleName === USER_ROLES.BUILDER_STAKEHOLDER_LEVEL_1_ROLENAME ||
    roleName === USER_ROLES.BUILDER_STAKEHOLDER_LEVEL_2_ROLENAME ||
    roleName === USER_ROLES.BUILDER_STAKEHOLDER_LEVEL_3_ROLENAME
      ? true
      : false;

  const isStakeholderUser3 =
    roleName === USER_ROLES.BUILDER_STAKEHOLDER_LEVEL_3_ROLENAME ? true : false;
  const handleCloseStateModal = () => {
    setOpenStateModal(false);
  };

  const handleContinue = () => {
    const baseUrl = `${process.env.REACT_APP_REACT_TAKER_URL_REDIRECT}/edit/${surveyFilingId}`;
    window.open(baseUrl, "_blank");
    handleCloseStateModal();
  };

  useEffect(() => {
    dispatch(resetHomeSurveyData());
    dispatch(resetBatchUpdateData());
    dispatch(resetSurveyBuilderData());
  }, []);

  useEffect(() => {
    const updateSurveyOrg = async () => {
      if (surveyRespondent) {
        const surveyOrgPayload = JSON.parse(
          JSON.stringify(surveyRespondent?.surveyOrganization)
        );
        surveyOrgPayload.stateCode = { id: selectedState };
        await updateSurveyOrganization(surveyOrgPayload);
        setIsButtonEnabled(true);
      }
    };

    updateSurveyOrg();
  }, [selectedState]);

  useEffect(() => {
    if (personId) {
      handleOtherSurveysToggle(true, false);
    }
  }, [personId]);

  useEffect(() => {
    const setColumns = Object.entries(columnVisibility)
      .filter(([key, value]) => value === true)
      .map(([key, value]) => key);

    const setColumnSettings = async () => {
      try {
        await setPersonDisplaySettings({
          personId: personId,
          page: "BuilderHome",
          columns: setColumns,
          filterCriteria: null,
          createdBy: userName,
        });
      } catch (error) {
        console.error("Error setting person column settings:", error);
      }
    };
    if (Object.keys(columnVisibility).length !== 0) {
      setColumnSettings();
    }
  }, [columnVisibility, personId, userName]);

  useEffect(() => {
    const fetchColumnSettings = async () => {
      try {
        const res = await getPersonDisplaySettings({
          personId: personId,
          page: "BuilderHome",
        });
        if (Array.isArray(res?.data) && res?.data?.length > 0) {
          const fetchedColumnObj = res.data
            .map((item) => {
              if (item?.type === "column") {
                return item?.name;
              }
            })
            .reduce((obj, columnName) => {
              if (columnName) {
                obj[columnName] = true;
              }
              return obj;
            }, {});

          for (const key in initialColumns) {
            if (!fetchedColumnObj.hasOwnProperty(key)) {
              fetchedColumnObj[key] = false;
            }
          }
          fetchedColumnObj["surveyName"] = true;
          setColumnVisibility(fetchedColumnObj);
        } else setColumnVisibility(initialColumns);
      } catch (error) {
        console.error("Error fetching person display settings:", error);
      }
    };
    if (personId) {
      fetchColumnSettings();
    }
  }, [personId]);

  const fetchSurveysWithResponse = async (surveys) => {
    const surveysWithResponse = await Promise.all(
      surveys.map(async (survey) => {
        const responseCount = await getSurveyFilingCount(survey.id);
        return {
          ...survey,
          responseCount: responseCount?.data,
        };
      })
    );
    return surveysWithResponse;
  };

  const fetchSurveys = async (orgId) => {
    try {
      const res = await getSurveyByOrg(orgId);
      const surveysWithResponse = await fetchSurveysWithResponse(res.data);
      setSurveys(surveysWithResponse);
    } catch (error) {
      console.error("Error fetching surveys:", error);
    }
  };

  const handleMySurveysToggle = async (checked, unchecked) => {
    if (checked) {
      setShowMySurveys(true);
      setShowOtherSurveys(false);
      const res = await getSurveyByPersonId(personId);
      const surveysWithResponse = await fetchSurveysWithResponse(res.data);
      setSurveys(surveysWithResponse);
    } else if (unchecked) {
      setShowMySurveys(false);
      fetchSurveys(orgId);
    }
  };

  const handleOtherSurveysToggle = async (checked, unchecked) => {
    if (checked) {
      setShowMySurveys(false);
      setShowOtherSurveys(true);
      const res = await getOtherSurveysByOrgIdPersonId({
        orgId: orgId,
        personId: personId,
      });
      const surveysWithResponse = await fetchSurveysWithResponse(res.data);
      setSurveys(surveysWithResponse);
      setShowLoading(false);
    } else if (unchecked) {
      setShowOtherSurveys(false);
      fetchSurveys(orgId);
    }
  };
  const viewSurveyInfo = async (data) => {
    const payload = {
      id: data.id,
      surveyName: data.surveyName,
      status: data.status,
      createdDate: data.createdDate,
      deactivateDate: data.deactivateDate,
      createdBy: data.createdBy,
    };
    setStatusInfo(payload);
    setOpenSurveyInfoModal(true);
  };
  const routeChange = () => {
    navigate("/new-survey");
  };
  const gotToResponseReport = async (row) => {
    dispatch(setSelectedPanelId(null));
    navigate("/response-report", {
      state: {
        surveyId: row?.original?.parentSurveyId
          ? row?.original?.parentSurveyId
          : row?.original?.id,
        surveyPanelId: row?.original?.processSurvey,
        surveyName: row?.original?.surveyName,
      },
    });
  };
  useEffect(() => {
    if (showMySurveys) {
      handleMySurveysToggle();
    } else if (showOtherSurveys) {
      handleOtherSurveysToggle();
    }
  }, [showMySurveys, showOtherSurveys]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchSurveyFilingStatuses());
        await dispatch(fetchStates());
        await dispatch(fetchRegions());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    dispatch(checkLoginStatus()).then((res) => {
      // console.log("the response of login api: ", res);
      if (res?.payload) {
        dispatch(getLoginAuthenticated());
        navigate("/");
      } else {
        // handle unauthorised case.
        window.location.href = `${BASE_URL}/builder/ssoBuilderLogin`;
        // window.location.href =  'http://localhost:8080/api/v1/taker/ssoAnnualTakerLogin';
      }
    });
  }, [dispatch]);
  const handleCloseSurveyInfoModal = () => {
    setStatusInfo({});
    setOpenSurveyInfoModal(false);
  };
  const handleSuccess = () => {
    setSuccessSnackbarOpen(true);
    setAlertMsg("Copy survey completed successfully");
    setAlertSeverity("success");
    setConfirmTitle(null);
    setConfirmId(0);
    setConfirmOpen(false);
    handleMySurveysToggle(true, false);
  };
  const openCopySurvey = async (srId, name) => {
    setConfirmTitle(
      "Click OK to make a copy of the <b>" + name + "</b> survey."
    );
    setConfirmId(srId);
    setConfirmOpen(true);
  };
  const copyEntireSurvey = async () => {
    try {
      const response = await copyBuilderSurvey({
        surveyId: confirmId,
        organizationId: orgId,
        personId: personId,
      });

      if (response?.status === 200) {
        handleSuccess();
      } else if (
        response?.response?.status === 400 ||
        response?.data?.status === 400
      ) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Error copying survey");
        setAlertSeverity("error");
        setConfirmTitle(null);
        setConfirmId(0);
        setConfirmOpen(false);
      }
    } catch (error) {
      console.log("Error copying survey.", error);
      return null;
    }
  };

  const openDeleteSurveyData = async (srId, name) => {
    setConfirmTitle(
      "This will delete all of the data for the <b>" +
        name +
        "</b> survey, and cannot be undone."
    );
    setConfirmDataId(srId);
    setConfirmDeleteDataOpen(true);
  };

  const openDeleteSurvey = async (srId, name) => {
    setConfirmTitle(
      "This will delete the <b>" +
        name +
        "</b> survey and all of its data, and cannot be undone."
    );
    setConfirmId(srId);
    setConfirmDeleteOpen(true);
  };

  const deleteSurveyDataCall = async () => {
    try {
      const response = await deleteSurveyData(confirmDataId);

      if (response?.status === 200) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Survey data deleted successfully");
        setAlertSeverity("success");
        setConfirmTitle(null);
        setConfirmDataId(0);
        setConfirmOpen(false);
      } else if (
        response?.response?.status === 400 ||
        response?.data?.status === 400
      ) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Error deleting survey data");
        setAlertSeverity("error");
        setConfirmTitle(null);
        setConfirmDataId(0);
        setConfirmDeleteDataOpen(false);
      }
    } catch (error) {
      console.log("Error deleting survey data.", error);
      return null;
    }
  };

  const deleteSurvey = async () => {
    try {
      const response = await deleteSelectedSurvey(confirmId);

      if (response?.status === 200) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Survey deleted successfully");
        setAlertSeverity("success");
        setConfirmTitle(null);
        setConfirmId(0);
        setConfirmOpen(false);
        if (showMySurveys) {
          handleMySurveysToggle(true, false);
        } else if (showOtherSurveys) {
          handleOtherSurveysToggle(true, false);
        } else {
          handleOtherSurveysToggle(false, true);
        }
      } else if (
        response?.response?.status === 400 ||
        response?.data?.status === 400
      ) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Error deleting survey");
        setAlertSeverity("error");
        setConfirmTitle(null);
        setConfirmId(0);
        setConfirmDeleteOpen(false);
      }
    } catch (error) {
      console.log("Error deleting survey.", error);
      return null;
    }
  };

  const data = useMemo(() => {
    return surveys?.map((survey) => {
      return {
        id: survey?.id,
        parentSurveyId: survey?.parentSurveyId,
        surveyName: getTextFromHTML(survey?.name),
        status: survey?.status?.name,
        responses: survey?.responseCount,
        createdDate: formatDate(survey?.dateCreated),
        lastUpdated: formatDate(survey?.dateModified),
        owner: survey?.ownerOrganization?.name,
        panelMgr: survey?.surveyPanel?.id,
        processSurvey: survey?.surveyPanel?.id,
        deactivateDate: survey?.deactivateDate,
        createdBy: survey?.createdBy,
        ownerOrganizationId: survey?.ownerOrganization?.id,
        deactivated: survey?.deactivated,
      };
    });
  }, [surveys]);

  const createNewSurveyFiling = async (surveyId, srId) => {
    try {
      const response = await createSurveyFiling({
        surveyId: surveyId,
        surveyRespondentId: srId,
      });
      const newSurveyFilingId = await response?.data?.id;
      return newSurveyFilingId;
    } catch (error) {
      console.log("Error creating Survey filing", error);
      return null;
    }
  };

  const openPreview = async (surveyId, ownerOrgId) => {
    setPreviewLoading(true);

    if (ownerOrgId == "8002") {
      setIsButtonEnabled(false);
      setOpenStateModal(true);
    }
    setSurveyRespondent(null);
    setSelectedState(null);

    try {
      const response = await getRespondentByUsername();
      const surveyRespondent = response?.data[response?.data.length - 1];
      setSurveyRespondent(surveyRespondent);
      const surveyRespondentId = response?.data[response?.data.length - 1]?.id;

      const surveyFiling = await getSurveyFiling({
        surveyId,
        surveyRespondentId,
      });

      if (Array.isArray(surveyFiling?.data) && surveyFiling?.data?.length > 0) {
        for (const filing of surveyFiling?.data) {
          const surveyFilingId = filing?.id;
          await deleteSurveyFiling(surveyFilingId);
        }
      }

      const newSurveyFilingId = await createNewSurveyFiling(
        surveyId,
        surveyRespondentId
      );

      setSurveyFilingId(newSurveyFilingId);
      const surveyOrgState = await response?.data[response?.data.length - 1]
        ?.surveyOrganization?.stateCode?.id;
      if (ownerOrgId == "8002") {
        setSelectedState(surveyOrgState);
        setIsButtonEnabled(true);
      } else {
        setSelectedState(stateCodeId ? stateCodeId : 1);

        const baseUrl = `${process.env.REACT_APP_REACT_TAKER_URL_REDIRECT}/edit/${newSurveyFilingId}`;
        window.open(baseUrl, "_blank");
      }
    } catch (error) {
      console.error("Preview error:", error);
    } finally {
      setPreviewLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "surveyName",
        header: "Survey Name",
        size: 200,
        enableHiding: false,
        Cell: ({ row }) => (
          <>
            <Typography variant="body2" component="span">
              {row?.original?.surveyName}
            </Typography>
          </>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 150,
      },
      {
        accessorKey: "responses",
        header: "Responses",
        size: 50,
      },
      {
        accessorKey: "owner",
        header: "Owner",
        size: 200,
      },
      {
        accessorKey: "createdDate",
        header: "Created Date",
        size: 200,
      },
      {
        accessorKey: "lastUpdated",
        header: "Last Updated",
        size: 200,
      },
      {
        accessorKey: "surveyInfo",
        header: "Survey Info",
        size: 50,
        Cell: ({ row }) => (
          <Tooltip title="View Survey Info" arrow>
            <InfoOutlinedIcon
              sx={{
                cursor: "pointer",
                width: 20,
                height: 20,
                color: theme.palette.blue.main,
                background: theme.palette.blue.light,
                borderRadius: "10px",
                padding: "10px",
              }}
              onClick={() => viewSurveyInfo(row?.original)}
            />
          </Tooltip>
        ),
      },
      {
        accessorKey: "modifySurvey",
        header: "Modify Survey",
        size: 50,
        Cell: ({ row }) => (
          <Tooltip
            title={
              (isSuperUser ||
                isBuilderAdminUser ||
                (isStakeholderUser &&
                  row?.original?.ownerOrganizationId === orgId)) &&
              isOrgTypeMarketResearch
                ? "Modify Survey"
                : roleMessage
            }
            arrow
          >
            <EditOutlinedIcon
              sx={{
                cursor:
                  (isSuperUser ||
                    isBuilderAdminUser ||
                    (isStakeholderUser &&
                      row?.original?.ownerOrganizationId === orgId)) &&
                  isOrgTypeMarketResearch
                    ? "pointer"
                    : "not-allowed",
                width: 20,
                height: 20,
                color: theme.palette.green.main,
                background: theme.palette.green.light,
                borderRadius: "10px",
                padding: "10px",
              }}
              onClick={() => {
                if (
                  (isSuperUser ||
                    isBuilderAdminUser ||
                    (isStakeholderUser &&
                      row?.original?.ownerOrganizationId === orgId)) &&
                  isOrgTypeMarketResearch
                ) {
                  navigate("/survey-builder");
                  dispatch(setSelectedSurveyId(row?.original?.id));
                  dispatch(setSelectedSurveyName(row?.original?.surveyName));
                  dispatch(setPreviewSurveyId(row?.original?.id));
                }
              }}
            />
          </Tooltip>
        ),
      },
      {
        accessorKey: "copySurvey",
        header: "Copy Survey",
        size: 50,
        Cell: ({ row }) => (
          <Tooltip
            title={
              isBuilderGroupUser ||
              (isStakeholderUser &&
                row?.original?.ownerOrganizationId !== orgId)
                ? roleMessage
                : "Copy Survey"
            }
            arrow
          >
            <ContentCopyOutlinedIcon
              sx={{
                cursor:
                  isBuilderGroupUser ||
                  (isStakeholderUser &&
                    row?.original?.ownerOrganizationId !== orgId)
                    ? "not-allowed"
                    : "pointer",
                width: 20,
                height: 20,
                color: theme.palette.pink.main,
                background: theme.palette.pink.light,
                borderRadius: "10px",
                padding: "10px",
              }}
              onClick={() => {
                if (
                  (!isBuilderGroupUser && !isStakeholderUser) ||
                  (isStakeholderUser &&
                    row?.original?.ownerOrganizationId === orgId)
                ) {
                  openCopySurvey(row?.original?.id, row?.original?.surveyName);
                }
              }}
            />
          </Tooltip>
        ),
      },
      {
        accessorKey: "preview",
        header: "Preview",
        size: 50,
        Cell: ({ row }) => (
          <Tooltip
            title={isBuilderGroupUser ? roleMessage : "Preview Survey"}
            arrow
          >
            <PreviewOutlinedIcon
              sx={{
                cursor: isBuilderGroupUser ? "not-allowed" : "pointer",
                width: 20,
                height: 20,
                color: theme.palette.yellow.main,
                background: theme.palette.yellow.light,
                borderRadius: "10px",
                padding: "10px",
              }}
              onClick={() => {
                if (!isBuilderGroupUser && !previewLoading) {
                  openPreview(
                    row?.original?.id,
                    row?.original?.ownerOrganizationId
                  );
                }
              }}
            />
          </Tooltip>
        ),
      },
      {
        accessorKey: "panelMgr",
        header: "Panel MGR",
        size: 50,
        Cell: ({ row }) => (
          <Tooltip
            title={isBuilderGroupUser ? roleMessage : "Panel Management"}
            arrow
          >
            <GroupOutlinedIcon
              sx={{
                cursor: isBuilderGroupUser ? "not-allowed" : "pointer",
                width: 20,
                height: 20,
                color: theme.palette.purple.main,
                background: theme.palette.purple.light,
                borderRadius: "10px",
                padding: "10px",
              }}
              onClick={() => {
                if (!isBuilderGroupUser) {
                  navigate("/panel-mgr", {
                    state: {
                      surveyId: row?.original?.parentSurveyId
                        ? row?.original?.parentSurveyId
                        : row?.original?.id,
                      surveyName: row?.original?.surveyName,
                      surveyPanelId: row?.original?.panelMgr,
                    },
                  });
                }
              }}
            />
          </Tooltip>
        ),
      },
      {
        accessorKey: "fileMgr",
        header: "File MGR",
        size: 50,
        Cell: ({ row }) => (
          <Tooltip
            title={
              !isBuilderGroupUser ||
              (isBuilderGroupUser &&
                row?.original?.deactivateDate !== null &&
                row?.original?.deactivateDate !== undefined &&
                dateInPast(row?.original?.deactivateDate)) ||
              (isBuilderGroupUser && row?.original?.deactivated === true)
                ? "Import & Export & Filtering"
                : roleMessage
            }
            arrow
          >
            <FolderOutlinedIcon
              sx={{
                cursor:
                  !isBuilderGroupUser ||
                  (isBuilderGroupUser &&
                    row?.original?.deactivateDate !== null &&
                    row?.original?.deactivateDate !== undefined &&
                    dateInPast(row?.original?.deactivateDate)) ||
                  (isBuilderGroupUser && row?.original?.deactivated === true)
                    ? "pointer"
                    : "not-allowed",
                width: 20,
                height: 20,
                color: theme.palette.lightBlue.main,
                background: theme.palette.lightBlue.light,
                borderRadius: "10px",
                padding: "10px",
              }}
              onClick={() => {
                if (
                  !isBuilderGroupUser ||
                  (isBuilderGroupUser &&
                    row?.original?.deactivateDate !== null &&
                    row?.original?.deactivateDate !== undefined &&
                    dateInPast(row?.original?.deactivateDate)) ||
                  (isBuilderGroupUser && row?.original?.deactivated === true)
                )
                  navigate("/file-mgr", {
                    state: {
                      surveyId: row?.original?.id,
                      surveyName: row?.original?.surveyName,
                      surveyPanelId: row?.original?.processSurvey,
                      visitPanelImport: false,
                    },
                  });
              }}
            />
          </Tooltip>
        ),
      },
      {
        accessorKey: "processSurvey",
        header: "Process Survey",
        size: 50,
        Cell: ({ row }) => (
          <Tooltip title="Survey Processing" arrow>
            <LoopOutlinedIcon
              sx={{
                cursor: "pointer",
                width: 20,
                height: 20,
                color: theme.palette.orange.main,
                background: theme.palette.orange.light,
                borderRadius: "10px",
                padding: "10px",
              }}
              onClick={() => {
                navigate("/process-survey", {
                  state: {
                    surveyId: row?.original?.parentSurveyId
                      ? row?.original?.parentSurveyId
                      : row?.original?.id,
                    surveyPanelId: row?.original?.processSurvey,
                  },
                });
              }}
            />
          </Tooltip>
        ),
      },
      {
        accessorKey: "batchUpdate",
        header: "Batch Update",
        size: 50,
        Cell: ({ row }) => (
          <Tooltip
            title={
              isBuilderGroupUser ||
              (isStakeholderUser &&
                row?.original?.ownerOrganizationId !== orgId)
                ? roleMessage
                : "Batch Update"
            }
            arrow
          >
            <UpdateOutlinedIcon
              sx={{
                cursor:
                  isBuilderGroupUser ||
                  (isStakeholderUser &&
                    row?.original?.ownerOrganizationId !== orgId)
                    ? "not-allowed"
                    : "pointer",
                width: 20,
                height: 20,
                color: theme.palette.lightPink.main,
                background: theme.palette.lightPink.light,
                borderRadius: "10px",
                padding: "10px",
              }}
              onClick={() => {
                if (
                  (!isBuilderGroupUser && !isStakeholderUser) ||
                  (isStakeholderUser &&
                    row?.original?.ownerOrganizationId === orgId)
                ) {
                  navigate("/batch-update");
                  dispatch(setSelectedSurveyId(row?.original?.id));
                  dispatch(setSelectedSurveyName(row?.original?.surveyName));
                  dispatch(
                    setSelectedSurveyPanelId(row?.original?.processSurvey)
                  );
                }
              }}
            />
          </Tooltip>
        ),
      },
      {
        accessorKey: "reports",
        header: "Reports",
        size: 50,
        Cell: ({ row }) => (
          <Tooltip title="Response Report" arrow>
            <DescriptionOutlinedIcon
              sx={{
                cursor: "pointer",
                width: 20,
                height: 20,
                color: theme.palette.darkBlue.main,
                background: theme.palette.darkBlue.light,
                borderRadius: "10px",
                padding: "10px",
              }}
              onClick={() => {
                gotToResponseReport(row);
              }}
            />
          </Tooltip>
        ),
      },
      {
        accessorKey: "blankSurvey",
        header: "Blank Survey",
        size: 50,
        Cell: ({ value }) => (
          <Tooltip
            title={
              isBuilderGroupUser ? roleMessage : "This feature is coming soon"
            }
            arrow
          >
            <InsertDriveFileOutlinedIcon
              sx={{
                cursor: isBuilderGroupUser ? "not-allowed" : "pointer",
                width: 20,
                height: 20,
                color: theme.palette.brown.main,
                background: theme.palette.brown.light,
                borderRadius: "10px",
                padding: "10px",
              }}
            />
          </Tooltip>
        ),
      },
      {
        accessorKey: "delete",
        header: "Delete Survey",
        size: 50,
        Cell: ({ row }) => (
          <Tooltip
            title={
              isBuilderGroupUser ||
              (isStakeholderUser &&
                row?.original?.ownerOrganizationId !== orgId)
                ? roleMessage
                : "Delete Survey"
            }
            arrow
          >
            <DeleteOutlinedIcon
              sx={{
                cursor:
                  isBuilderGroupUser ||
                  (isStakeholderUser &&
                    row?.original?.ownerOrganizationId !== orgId)
                    ? "not-allowed"
                    : "pointer",
                width: 20,
                height: 20,
                color: theme.palette.gray.main,
                background: theme.palette.gray.light,
                borderRadius: "10px",
                padding: "10px",
              }}
              onClick={() => {
                if (
                  (!isBuilderGroupUser && !isStakeholderUser) ||
                  (isStakeholderUser &&
                    row?.original?.ownerOrganizationId === orgId)
                ) {
                  openDeleteSurvey(
                    row?.original?.id,
                    row?.original?.surveyName
                  );
                }
              }}
            />
          </Tooltip>
        ),
      },
      {
        accessorKey: "deletesurveydata",
        header: "Delete Survey Data",
        size: 50,
        Cell: ({ row }) => (
          <Tooltip
            title={
              isBuilderGroupUser ||
              (isStakeholderUser &&
                row?.original?.ownerOrganizationId !== orgId)
                ? roleMessage
                : "Delete Survey Data"
            }
            arrow
          >
            <RecyclingOutlinedIcon
              sx={{
                cursor:
                  isBuilderGroupUser ||
                  (isStakeholderUser &&
                    row?.original?.ownerOrganizationId !== orgId)
                    ? "not-allowed"
                    : "pointer",
                width: 20,
                height: 20,
                color: theme.palette.gray.main,
                background: theme.palette.gray.light,
                borderRadius: "10px",
                padding: "10px",
              }}
              onClick={() => {
                if (
                  (!isBuilderGroupUser && !isStakeholderUser) ||
                  (isStakeholderUser &&
                    row?.original?.ownerOrganizationId === orgId)
                ) {
                  openDeleteSurveyData(
                    row?.original?.id,
                    row?.original?.surveyName
                  );
                }
              }}
            />
          </Tooltip>
        ),
      },
    ],
    [orgId, isBuilderGroupUser, isStakeholderUser, previewLoading]
  );

  const table = useMaterialReactTable({
    columns,
    data,
    state: { columnVisibility },
    onColumnVisibilityChange: setColumnVisibility,
    initialState: { density: "compact" },
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
  });

  return showLoading ? (
    <LoadingText />
  ) : (
    <div style={{ padding: "30px" }}>
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="label" color="primary">
          Welcome {userName}, ({orgName})
        </Typography>
      </div>
      <div style={{ marginBottom: "20px" }}></div>
      <Grid container rowSpacing={1}>
        <Grid item xs={3}>
          {(isSuperUser || isBuilderAdminUser) && isOrgTypeMarketResearch && (
            <Button
              variant="outlined"
              size="small"
              startIcon={<AddOutlinedIcon />}
              onClick={() => routeChange()}
            >
              Create a New Survey
            </Button>
          )}
        </Grid>
        <Grid item xs={9} sx={{ textAlign: "right" }}>
          <FormControlLabel
            control={
              <Switch
                checked={showMySurveys}
                onChange={(event) =>
                  handleMySurveysToggle(
                    event.target.checked,
                    !event.target.checked
                  )
                }
              />
            }
            label="My surveys"
            sx={{
              marginLeft: "10px",
            }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={showOtherSurveys}
                onChange={(event) =>
                  handleOtherSurveysToggle(
                    event.target.checked,
                    !event.target.checked
                  )
                }
              />
            }
            label="Other surveys"
            sx={{
              marginLeft: "10px",
            }}
          />
        </Grid>
      </Grid>
      <div style={{ marginBottom: "10px" }}></div>
      <MaterialReactTable table={table} />
      <Modal open={openSurveyInfoModal} onClose={handleCloseSurveyInfoModal}>
        <>
          <SurveyInfo
            statusInfo={statusInfo}
            handleCloseSurveyInfo={handleCloseSurveyInfoModal}
          />
        </>
      </Modal>
      <ConfirmDialog
        title={confirmTitle}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={copyEntireSurvey}
        pageName="Home"
      ></ConfirmDialog>
      <ConfirmDialog
        title={confirmTitle}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={deleteSurvey}
        pageName="DelSurvey"
      ></ConfirmDialog>
      <ConfirmDialog
        title={confirmTitle}
        open={confirmDeleteDataOpen}
        setOpen={setConfirmDeleteDataOpen}
        onConfirm={deleteSurveyDataCall}
        pageName="DelSurvey"
      ></ConfirmDialog>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
      <StateSelectModal
        open={openStateModal}
        handleClose={handleCloseStateModal}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        handleContinue={handleContinue}
        isButtonEnabled={isButtonEnabled}
        setIsButtonEnabled={setIsButtonEnabled}
      />
    </div>
  );
};

export default Homepage;
