import React, { useState } from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { htmlParser } from "../../../Utility/constants";

const PiInfoPopup = ({ infoOpen, setInfoOpen, validateFile }) => {
  const handleClose = () => setInfoOpen(false);

  const info = `<div>
   <p style="text-align: center;"><strong>Panel Import Instructions</strong></p>
   <p>The panel import process is done in two steps:</p>
   <p><strong>Step 1. Validate the panel import file:</strong></p>
   <p>a. Select the file to import. The file should contain tab-separated data.</p>
   <p> b. The system validates the file. This step checks that:</p>
   <ul>
      <li>
         The header record in the file contains all of these required fields (the ordering of the fields in the file doesn't matter):
         <ul>
            <li>SR_CUSTOM_ID (respondent ID)</li>
            <li>Last Name (respondent last name)</li>
            <li>Title (respondent title)</li>
            <li>Username (respondent username. This must be an email address.)</li>
            <li>SO_CUSTOM_ID (survey organization id)</li>
            <li>Name (survey organization name)</li>
         </ul>
      </li>
      <li>Each data record in the file has the above fields populated.</li>
   </ul>
   </li>
   <ul>
   </ul>
   <p>
      c. If any errors occur when validating the file, those are listed. All errors will need to be resolved before being allowed to actually import the data.
   </p>
   <p>
      d. After correcting any errors in the file, select the file again for it to be re-validated.
   </p>
   <p><strong>Step 2. Import the data:</strong></p>
   <p>Once the file is successfully validated, you will be presented with the option to actually import the data. When prompted:</p>
   <p>a. Select the file again.</p>
   <p>b. The import will automatically begin. A link will appear to view the progress of the import.</p>
</div>`;

  return (
    <Modal open={infoOpen} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ position: "absolute", top: 8, right: 8 }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography>{htmlParser(info)}</Typography>
      </Box>
    </Modal>
  );
};

export default PiInfoPopup;
