import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  FormControlLabel,
  InputAdornment,
  Checkbox,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Stack,
  Modal,
  Tooltip,
} from "@mui/material";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import {
  createMatrixQuestionGroup,
  updateQuestionGroup,
  updateHeading,
  deleteHeading,
  createHeading,
  fullCommonGroupByIdForBuilder,
} from "../../../Survey/Survey.service";
import {
  setSavedQuesGrp,
  setTriggerMatrixGrp,
} from "../../../Survey/Survey.slice";
import {
  initMatrixFormData,
  emptyFieldRow,
  fieldTypeOptions,
  initialresData,
  mapResponseData,
} from "./AddQuesConstants";

import TextField from "../../../../components/Common/TextField";
import RichEditor from "../../../../components/Common/RichEditor";
import CommonResponseTable from "./CommonResponseTable";
import theme from "../../../../theme";
import HeadingFieldAttributes from "./HeadingFieldAttributes";
import UserlabelModal from "./UserlabelModal";
import { useLocation } from "react-router-dom";

function showColumnLabels(colObj) {
  let tablehead = "Questions";
  return (
    <>
      {colObj.map((col, index) => {
        if (index !== 0) tablehead = "Answer Column " + index;
        return <TableCell key={`colcell${index}`}>{tablehead}</TableCell>;
      })}
    </>
  );
}
const CommonResponseGroupInfo = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedPageId = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedPage?.page?.id
  );

  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );

  const selectedField = useSelector(
    (state) => state?.survey?.surveyBuilderData.selectedField
  );

  const EditQuesGrpId = useSelector(
    (state) => state?.survey?.surveyBuilderData.EditQuesGrpId
  );

  const sortedResData = initialresData.sort((a, b) => a.sequence - b.sequence);
  const placements = ["No Title", "Above Title", "Below Title"];
  const [showGrpProp, setShowGrpProp] = useState(true);
  const [isCreateUpdateQuesGroup, setIsCreateUpdateQuesGroup] = useState(false);
  const [updateCategory, setUpdateCategory] = useState(null);
  const [headingInd, setHeadingInd] = useState(null);

  const [matrixFormData, setMatrixFormData] = useState(initMatrixFormData);

  const [groupTypeName, setGroupTypeName] = useState(null);

  const [isAddColumn, setIsAddColumn] = useState(false);
  const [createQuestionData, setCreateQuestionData] = useState([]);
  const [updateQuestionData, setUpdateQuestionData] = useState([]);

  const [openEditFieldModal, setOpenEditFieldModal] = useState(false);
  const [headingAtt, setHeadingAttr] = useState({});
  const [openUserLabelModal, setOpenUserLabelModal] = useState(false);

  const handleCloseUserLabelModal = () => {
    setOpenUserLabelModal(false);
  };

  useEffect(() => {
    if (location.state?.openLabelModal) {
      setOpenUserLabelModal(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (!EditQuesGrpId) {
      let seqNumber = 3001;
      let fSet = [];
      let qSet = [
        {
          id: null,
          dateCreated: null,
          dateModified: null,
          createdBy: null,
          modifiedBy: null,
          label: "",
          name: "",
          helptext: "",
          sequence: 0,
          fields: [],
        },
      ];
      for (
        let colNumber = 1;
        colNumber < matrixFormData["headings"].length;
        colNumber++
      ) {
        let firstN = emptyFieldRow();
        firstN.sequence = seqNumber;
        firstN.columnNumber = colNumber;
        fSet.push(firstN);
        seqNumber++;

        let secN = emptyFieldRow();
        secN.sequence = seqNumber;
        secN.columnNumber = colNumber;
        fSet.push(secN);
        seqNumber++;
      }
      qSet[0].fields = fSet;

      setCreateQuestionData(qSet);
    }
  }, [!EditQuesGrpId]);

  const generateQuestionFields = (result) => {
    let qSet = [];

    result.questions.forEach((ques, qInd) => {
      let fSet = [];
      if (ques.fields.length === 0) {
        let seqNumber = 6001;
        for (
          let colNumber = 1;
          colNumber < result.headings.length;
          colNumber++
        ) {
          let firstN = emptyFieldRow();
          firstN.sequence = seqNumber;
          firstN.columnNumber = colNumber;
          fSet.push(firstN);
          seqNumber++;

          let secN = emptyFieldRow();
          secN.sequence = seqNumber;
          secN.columnNumber = colNumber;
          fSet.push(secN);
          seqNumber++;
        }
        const qelement = {
          id: ques.id,
          dateCreated: ques.dateCreated,
          dateModified: ques.dateModified || "",
          createdBy: ques.createdBy,
          modifiedBy: ques.modifiedBy || "",
          label: ques.label || "",
          name: ques.name,
          helptext: ques.helptext || "",
          sequence: ques.sequence,
          fields: [],
        };
        qSet.push(qelement);
        qSet[qInd].fields = fSet;
      } else if (ques.fields.length > 0) {
        let nextSeqNumber = 8001; //ques.fields[ques.fields.length - 1].sequence + 1;
        for (
          let colNumber = 1;
          colNumber < result.headings.length;
          colNumber++
        ) {
          const filteredColumns = ques.fields.filter(
            (line) => line.columnNumber === colNumber
          );
          if (filteredColumns.length === 0) {
            let firstN = emptyFieldRow();
            firstN.sequence = nextSeqNumber;
            firstN.columnNumber = colNumber;
            fSet.push(firstN);
            nextSeqNumber++;

            let secN = emptyFieldRow();
            secN.sequence = nextSeqNumber;
            secN.columnNumber = colNumber;
            fSet.push(secN);
            nextSeqNumber++;
          } else if (filteredColumns.length === 1) {
            let firstN = filteredColumns[0];
            fSet.push(firstN);

            let secN = emptyFieldRow();
            secN.sequence = nextSeqNumber;
            secN.columnNumber = colNumber;
            fSet.push(secN);
            nextSeqNumber++;
          } else if (filteredColumns.length === 2) {
            const filteredObj = filteredColumns.sort(
              (a, b) => a.sequence - b.sequence
            );
            let firstN = filteredObj[0];
            fSet.push(firstN);

            let secN = filteredObj[1];
            fSet.push(secN);
          }
        }
        const qelement = {
          id: ques.id,
          dateCreated: ques.dateCreated,
          dateModified: ques.dateModified || "",
          createdBy: ques.createdBy,
          modifiedBy: ques.modifiedBy || "",
          label: ques.label || "",
          name: ques.name,
          helptext: ques.helptext || "",
          sequence: ques.sequence,
          fields: [],
        };
        qSet.push(qelement);
        qSet[qInd].fields = fSet;
      }
    });

    setUpdateQuestionData(qSet);
  };

  useEffect(() => {
    const setQues = async (groupid) => {
      try {
        const response = await fullCommonGroupByIdForBuilder(groupid);
        const resData = response?.data;
        if (resData && Object.keys(resData).length > 0 && resData.id) {
          setMatrixFormData(resData);
          // set questions
          if (resData.questions && resData.questions.length > 0) {
            generateQuestionFields(resData);
          } else {
            let seqNumber = 7001;
            let fSet = [];
            let qSet = [
              {
                id: null,
                dateCreated: null,
                dateModified: null,
                createdBy: null,
                modifiedBy: null,
                label: "",
                name: "",
                helptext: "",
                sequence: 0,
                fields: [],
              },
            ];
            for (
              let colNumber = 1;
              colNumber <= matrixFormData["headings"].length;
              colNumber++
            ) {
              let firstN = emptyFieldRow();
              firstN.sequence = seqNumber;
              firstN.columnNumber = colNumber;
              fSet.push(firstN);
              seqNumber++;

              let secN = emptyFieldRow();
              secN.sequence = seqNumber;
              secN.columnNumber = colNumber;
              fSet.push(secN);
              seqNumber++;
            }
            qSet[0].fields = fSet;

            setUpdateQuestionData(qSet);
          }
          setCreateQuestionData([]);
          dispatch(setSavedQuesGrp(resData));
          dispatch(setTriggerMatrixGrp(true));
          setIsAddColumn(false);
        }
      } catch (error) {
        console.error("Error setting quesgrp data:", error);
      }
    };
    if (EditQuesGrpId || isAddColumn) {
      let groupid = EditQuesGrpId;
      if (isAddColumn) {
        groupid = matrixFormData["id"];
        setMatrixFormData({});
      }
      setQues(groupid);
    }
  }, [EditQuesGrpId, isAddColumn]);

  useEffect(() => {
    if (selectedField) {
      setGroupTypeName(selectedField);
    }
  }, [selectedField]);

  const addResponseColumn = async () => {
    setIsAddColumn(false);
    dispatch(setTriggerMatrixGrp(false));
    const payload = {
      questionGroup: { id: matrixFormData["id"] },
      createdBy: userName,
      name: "",
      value: "",
      widthPct: 15,
      exclusiveAnswer: false,
      field: {
        id: null,
        dateCreated: null,
        dateModified: null,
        createdBy: null,
        modifiedBy: null,
        fieldType: {
          name: "Numeric Field",
        },
        sequence: 1,
        columnNumber: 1,
        minNumeric: null,
        maxNumeric: null,
        maxDigits: null,
        size: null,
        minDate: null,
        maxDate: null,
        userLabel: null,
        name: null,
        namePosition: "",
        horizontalAlign: "",
        fieldFlow: "NL",
        numLines: null,
        numPrecision: null,
        fieldValues: [],
        historyFlag: true,
        disabledField: false,
        readOnlyField: false,
        hideField: false,
        autoCommas: false,
        fillHistory: false,
        isRequired: false,
        maxLength: null,
        isMultiselect: false,
        isFloat: false,
      },
    };
    try {
      const response = await createHeading(payload);
      if (response?.data) {
        setIsAddColumn(true);
      }
    } catch (error) {
      console.log("Error creating heading", error);
      return null;
    }
  };
  const deleteAnswerColumn = async (id, colIndex) => {
    dispatch(setTriggerMatrixGrp(false));
    const response = await deleteHeading(id);
    if (response?.data && response?.data === true) {
      let resData = JSON.parse(JSON.stringify(matrixFormData));
      let headerData = resData.headings;
      headerData.splice(colIndex, 1);
      resData.headings = headerData;

      setMatrixFormData(resData);
      dispatch(setTriggerMatrixGrp(true));
    }
  };

  const openHeadingFieldBox = (row, id, index) => {
    if (id !== null) {
      setHeadingAttr({ row: row, index: index });
      setOpenEditFieldModal(true);
    }
  };
  const handleCloseEditFieldModal = () => {
    setOpenEditFieldModal(false);
    setHeadingAttr({});
  };

  const handleColumnFieldChangeWrapper = () => {
    handleCloseEditFieldModal();
    setIsAddColumn(true);
    setIsCreateUpdateQuesGroup(false);
  };
  const toggleGrpProp = () => {
    setShowGrpProp(!showGrpProp);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setMatrixFormData((prevData) => ({
        ...prevData,
        [name]: checked ? true : false,
      }));
    } else {
      setMatrixFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    setUpdateCategory("groupinfo");
    setIsCreateUpdateQuesGroup(true);
  };

  const handleHeadingChange = (e, catetory, index) => {
    const { value } = e.target;
    let newHeadings = JSON.parse(JSON.stringify(matrixFormData.headings));
    if (catetory === "hfieldtype") {
      newHeadings[index].field.fieldType.name = value;
    } else if (catetory === "width") {
      newHeadings[index].widthPct = value;
    } else if (catetory === "heading") {
      newHeadings[index].name = value;
    }
    setMatrixFormData((prevData) => ({
      ...prevData,
      headings: newHeadings,
    }));
    setIsCreateUpdateQuesGroup(true);
    setHeadingInd(index);
    setUpdateCategory(catetory);
  };

  useEffect(() => {
    const createMatrixQuesGroup = async () => {
      let mgrpData = { ...matrixFormData };

      let groupPayload = {
        id: null,
        page: { id: selectedPageId },
        groupType: { name: groupTypeName },
        createdBy: userName,
        groupLabel: matrixFormData["groupLabel"],
        name: matrixFormData["name"],
        description: matrixFormData["description"],
        descriptionPosition: matrixFormData["descriptionPosition"],
        helpText: matrixFormData["helpText"],
        shortName: matrixFormData["shortName"],
        hideGroup: matrixFormData["hideGroup"],
        tableWidthPct: matrixFormData["tableWidthPct"],
        headings: matrixFormData.headings,
      };

      try {
        const response = await createMatrixQuestionGroup(groupPayload);
        if (response?.data && !response?.data?.detail) {
          const result = response?.data;
          setIsCreateUpdateQuesGroup(false);
          setUpdateCategory(null);
          mgrpData["id"] = result.id;
          mgrpData["sequence"] = result.sequence;
          mgrpData["dateCreated"] = result.dateCreated;
          mgrpData["createdBy"] = result.createdBy;
          mgrpData["headings"] = result.headings;
          setMatrixFormData(mgrpData);
          dispatch(setSavedQuesGrp(response?.data));
          dispatch(setTriggerMatrixGrp(true));
        } else if (response?.data?.detail) {
          dispatch(setTriggerMatrixGrp(false));
          setIsCreateUpdateQuesGroup(false);
          setUpdateCategory(null);
        }
      } catch (error) {
        console.log("Error creating StandardMatrixQuestionGroup", error);
        return null;
      }
    };

    const updateMatrixQuesGroup = async () => {
      dispatch(setTriggerMatrixGrp(false));
      let groupPayload = {
        id: matrixFormData["id"],
        modifiedBy: userName,
        groupLabel: matrixFormData["groupLabel"],
        name: matrixFormData["name"],
        description: matrixFormData["description"],
        descriptionPosition: matrixFormData["descriptionPosition"],
        helpText: matrixFormData["helpText"],
        shortName: matrixFormData["shortName"],
        tableWidthPct: matrixFormData["tableWidthPct"],
        hideGroup: matrixFormData["hideGroup"],
        sequence: matrixFormData["sequence"],
      };
      try {
        const response = await updateQuestionGroup(groupPayload);
        if (response?.data && !response?.data?.detail) {
          setIsCreateUpdateQuesGroup(false);
          dispatch(setTriggerMatrixGrp(true));
          setUpdateCategory(null);
        }
      } catch (error) {
        console.log("Error updating StandardQuestionGroup", error);
        setIsCreateUpdateQuesGroup(false);
        setUpdateCategory(null);
        return null;
      }
    };
    const updateHeaderInfo = async () => {
      dispatch(setTriggerMatrixGrp(false));
      let headingPayload = matrixFormData["headings"][headingInd];
      if (headingInd !== 0) {
        let headingfieldtype = headingPayload.field.fieldType.name;
        headingPayload.field.fieldType = { name: headingfieldtype };
        headingPayload.field.fieldValues = [];

        if (
          headingfieldtype === "Radio Field" ||
          headingfieldtype === "Combobox Field" ||
          headingfieldtype === "CheckBox Field"
        ) {
          const mappedResData = mapResponseData(sortedResData);
          headingPayload.field.fieldValues = mappedResData.fieldValues;
        }
      }
      try {
        const response = await updateHeading(headingPayload);
        if (response?.data && !response?.data?.detail) {
          setIsCreateUpdateQuesGroup(false);
          dispatch(setTriggerMatrixGrp(true));
          setUpdateCategory(null);
          setHeadingInd(null);
        }
      } catch (error) {
        console.log("Error updating updateHeaderInfo", error);
        setIsCreateUpdateQuesGroup(false);
        setUpdateCategory(null);
        setHeadingInd(null);
        return null;
      }
    };
    if (matrixFormData["name"] && isCreateUpdateQuesGroup) {
      if (matrixFormData["id"] && updateCategory === "groupinfo") {
        updateMatrixQuesGroup();
      } else if (
        matrixFormData["id"] &&
        updateCategory !== "groupinfo" &&
        headingInd != null
      ) {
        updateHeaderInfo();
      } else createMatrixQuesGroup();
    }
  }, [matrixFormData, isCreateUpdateQuesGroup, updateCategory, headingInd]);

  return (
    <>
      <Typography
        color="primary"
        className="blueLinkText"
        onClick={toggleGrpProp}
      >
        {showGrpProp ? "-" : "+"} Group Information
      </Typography>
      {showGrpProp && (
        <Card sx={{ mb: 1 }}>
          <CardContent>
            <Box sx={{ mb: 1 }}>
              {matrixFormData && (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={2}>
                        <Typography variant="body1">Group Label</Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <RichEditor
                          name="groupLabel"
                          value={matrixFormData["groupLabel"]}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Typography variant="body1">Text *</Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <RichEditor
                          name="name"
                          value={matrixFormData["name"]}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body1">Table Width</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          type="number"
                          height="small"
                          name="tableWidthPct"
                          variant="outlined"
                          value={matrixFormData["tableWidthPct"]}
                          style={{ width: "90%" }}
                          InputProps={{
                            inputProps: {
                              max: 100,
                              min: 0,
                            },
                          }}
                          onBlur={handleChange}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <FormHelperText sx={{ fontSize: "12px" }}>
                          % (0-100: use lower value to reduce white spaces
                          between question and answers on the same line or vice
                          versa)
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body1">Short Name</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          height="small"
                          name="shortName"
                          variant="outlined"
                          value={matrixFormData["shortName"]}
                          style={{ width: "90%" }}
                          onBlur={handleChange}
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <FormHelperText sx={{ fontSize: "12px" }}>
                          (used in Batch Update)
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={2}>
                        <Typography variant="body1" sx={{ pl: 2 }}>
                          Description
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <FormControl
                          sx={{ width: "390px", marginBottom: "3px" }}
                        >
                          <InputLabel className="inputLabel">
                            Placement
                          </InputLabel>
                          <Select
                            size="small"
                            variant="outlined"
                            onChange={handleChange}
                            name="descriptionPosition"
                            label="Placement"
                            value={matrixFormData["descriptionPosition"]}
                          >
                            {placements.map((name, index) => (
                              <MenuItem key={`plc${index}`} value={name}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <RichEditor
                          name="description"
                          value={matrixFormData["description"]}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Typography variant="body1" sx={{ pl: 2 }}>
                          Help
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <RichEditor
                          name="helpText"
                          value={matrixFormData["helpText"]}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body1" sx={{ pl: 2 }}>
                          Hidden
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={matrixFormData["hideGroup"]}
                              name="hideGroup"
                              onChange={handleChange}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Box>
          </CardContent>
        </Card>
      )}

      {EditQuesGrpId &&
        (updateQuestionData.length == 0 || !matrixFormData["id"]) && (
          <>
            <Box>
              <Typography color="primary" variant="caption">
                Loading questions ...
              </Typography>{" "}
              <CircularProgress color="primary" />
            </Box>
          </>
        )}
      <Card sx={{ mb: 1 }}>
        <CardContent>
          <Box sx={{ mb: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography variant="label" sx={{ fontWeight: "bold" }}>
                  RESPONSES
                </Typography>
              </Grid>
              {matrixFormData["id"] && (
                <Grid item xs={8}>
                  <Typography
                    color="primary"
                    className="blueLinkText"
                    variant="caption"
                    onClick={() => {
                      addResponseColumn();
                    }}
                  >
                    &#10011; Add Response Column
                  </Typography>
                </Grid>
              )}
            </Grid>
            {matrixFormData &&
              Object.keys(matrixFormData)?.length > 0 &&
              matrixFormData["headings"].length > 0 && (
                <TableContainer
                  component={Paper}
                  id="tableContainerMatrixgroup"
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow
                        sx={{
                          "& th": {
                            color: theme.palette.blue.main,
                            fontWeight: "bold",
                            fontSize: "13px",
                            padding: "9px",
                          },
                        }}
                      >
                        <TableCell></TableCell>
                        <TableCell>Property</TableCell>
                        {showColumnLabels(matrixFormData["headings"])}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Width</TableCell>
                        {matrixFormData["headings"].map((row, index) => (
                          <TableCell key={`wd${index}`}>
                            <TextField
                              type="number"
                              height="small"
                              name={`txtwidth${index}`}
                              variant="outlined"
                              value={row.widthPct}
                              style={{ width: 100 }}
                              InputProps={{
                                inputProps: {
                                  max: 100,
                                  min: 0,
                                },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                              onBlur={(event) =>
                                handleHeadingChange(event, "width", index)
                              }
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Column Heading</TableCell>
                        {matrixFormData["headings"].map((row, index) => (
                          <TableCell key={`hd${index}`}>
                            <TextField
                              height="small"
                              variant="outlined"
                              value={row.name}
                              style={{ width: "60%" }}
                              onBlur={(event) =>
                                handleHeadingChange(event, "heading", index)
                              }
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell></TableCell>
                        <TableCell>Field Type *</TableCell>
                        {matrixFormData["headings"].map((row, index) => (
                          <TableCell key={`ftyp${index}`}>
                            {index === 0 && <></>}
                            {index !== 0 && (
                              <Stack direction="row" spacing={1}>
                                <FormControl sx={{ width: "60%" }}>
                                  <Select
                                    size="small"
                                    variant="outlined"
                                    value={row.field.fieldType.name}
                                    onChange={(event) =>
                                      handleHeadingChange(
                                        event,
                                        "hfieldtype",
                                        index
                                      )
                                    }
                                  >
                                    {fieldTypeOptions.map(
                                      (option, ftindex1) => (
                                        <MenuItem
                                          key={`ftype${ftindex1}`}
                                          value={option.name}
                                        >
                                          {option.name}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                                <Tooltip title="Edit field" arrow>
                                  <NoteAltOutlinedIcon
                                    sx={{
                                      cursor: "pointer",
                                      width: 17,
                                      height: 17,
                                      color: theme.palette.blue.main,
                                    }}
                                    onClick={() => {
                                      openHeadingFieldBox(row, row.id, index);
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip title="Update Group User Labels" arrow>
                                  <LabelOutlinedIcon
                                    sx={{
                                      cursor: "pointer",
                                      width: 17,
                                      height: 17,
                                      color: theme.palette.blue.main,
                                    }}
                                    onClick={() => {
                                      setOpenUserLabelModal(true);
                                    }}
                                  />
                                </Tooltip>
                              </Stack>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        {matrixFormData["id"] !== null && (
                          <>
                            {matrixFormData["headings"].map((row, index) => (
                              <TableCell key={`delCol${index}`}>
                                {index === 0 && <></>}
                                {index !== 0 &&
                                  matrixFormData["headings"].length != 2 && (
                                    <Typography
                                      color="primary"
                                      className="blueLinkText"
                                      variant="caption"
                                      onClick={() => {
                                        deleteAnswerColumn(row.id, index);
                                      }}
                                    >
                                      &#10005; Delete Column
                                    </Typography>
                                  )}
                              </TableCell>
                            ))}
                          </>
                        )}
                      </TableRow>
                      {createQuestionData.length > 0 &&
                        matrixFormData &&
                        !EditQuesGrpId && (
                          <TableRow sx={{ backgroundColor: "#c9cbd21f" }}>
                            <TableCell
                              sx={{ padding: 0, borderBottom: "none" }}
                              colSpan={2 + matrixFormData["headings"].length}
                            >
                              <CommonResponseTable
                                qGroupId={matrixFormData["id"]}
                                totalCols={
                                  matrixFormData["headings"].length - 1
                                }
                                matrixquestions={createQuestionData}
                              ></CommonResponseTable>
                            </TableCell>
                          </TableRow>
                        )}

                      {updateQuestionData.length > 0 &&
                        matrixFormData &&
                        matrixFormData["id"] && (
                          <TableRow sx={{ backgroundColor: "#c9cbd21f" }}>
                            <TableCell
                              sx={{ padding: 0, borderBottom: "none" }}
                              colSpan={2 + matrixFormData["headings"].length}
                            >
                              <CommonResponseTable
                                qGroupId={matrixFormData["id"]}
                                totalCols={
                                  matrixFormData["headings"].length - 1
                                }
                                matrixquestions={updateQuestionData}
                              ></CommonResponseTable>
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
          </Box>
        </CardContent>
      </Card>

      <Modal open={openEditFieldModal} onClose={handleCloseEditFieldModal}>
        <HeadingFieldAttributes
          handleCloseEditFieldModal={handleCloseEditFieldModal}
          handleColumnFieldChangeWrapper={handleColumnFieldChangeWrapper}
          attrRow={headingAtt}
          selectedField={selectedField}
        />
      </Modal>

      <Modal open={openUserLabelModal} onClose={handleCloseUserLabelModal}>
        <UserlabelModal handleCloseModal={handleCloseUserLabelModal} />
      </Modal>
    </>
  );
};

export default CommonResponseGroupInfo;
