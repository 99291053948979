import React from "react";
import { Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import Button from "../../../components/Common/Button";
import theme from "../../../theme";
import { setImportCurrentView } from "../../Survey/Survey.slice";

const ErrorList = ({ fileErrors }) => {
  const listItems = fileErrors.map((error, index) => (
    <li key={index}>{error}</li>
  ));
  return <ul>{listItems}</ul>;
};
const PiProgress = ({ fileErrors, totalFilings, totalAnswers }) => {
  const dispatch = useDispatch();

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography
            variant="label"
            color="primary"
            sx={{ marginBottom: "20px" }}
          >
            Panel Import Progress Page
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => dispatch(setImportCurrentView(1))}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
        <Grid item xs={2}>
          <Typography variant="subtitle2">
            <b>TOTAL RECORDS</b>
          </Typography>
          <Typography variant="body1" color="primary">
            {totalFilings}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2">
            <b>TOTAL ANSWERS</b>
          </Typography>
          <Typography variant="body1" color="primary">
            {totalAnswers}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2">
            <b>PANEL IMPORT ERRORS</b>
          </Typography>
          <Typography variant="body1" color="primary">
            {fileErrors?.length}
          </Typography>
        </Grid>
      </Grid>
      <Typography
        variant="subtitle2"
        color={theme.palette.error.main}
        sx={{ textDecoration: "underline" }}
      >
        PANEL IMPORT ERRORS
      </Typography>
      <div>
        The following errors were found when validating the file. Please correct
        the errors and then resubmit the file. Once the file validates
        successfully, you will be given the option to import the file.
      </div>

      <Grid item xs={true}>
        {fileErrors?.length > 0 && <ErrorList fileErrors={fileErrors} />}
      </Grid>
    </div>
  );
};

export default PiProgress;
