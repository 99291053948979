import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Button, TextField, Box, Checkbox } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  commonGroupFieldLabels,
  updateCommonGroupFieldLabels,
} from "../../../Survey/Survey.service";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import Tooltip from "../../../../components/Common/Tooltip";
import theme from "../../../../theme";
import {
  setSelectedRuleField,
  setSurveyBuilderCurrentView,
} from "../../../Survey/Survey.slice";
import { useNavigate } from "react-router-dom";

const UserlabelModal = ({ handleCloseModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fieldLabels, setFieldLabels] = useState({
    headings: [],
    questions: [],
  });

  const savedQuesGrp = useSelector(
    (state) => state?.survey?.surveyBuilderData.savedQuesGrp
  );

  const handleChange = (e, questionId, columnNumber, isCheckbox = false) => {
    const { value, checked } = e.target;
    setFieldLabels((prevFieldLabels) => {
      const updatedQuestions = prevFieldLabels.questions.map((question) => {
        if (question.id === questionId) {
          return {
            ...question,
            fields: question.fields.map((field) =>
              field.columnNumber === columnNumber
                ? {
                    ...field,
                    ...(isCheckbox ? {} : { userLabel: value }),
                    fieldType: {
                      name: isCheckbox
                        ? checked
                          ? "Auto Calc Total Field"
                          : "Numeric Field"
                        : field.fieldType.name,
                    },
                  }
                : field
            ),
          };
        }
        return question;
      });
      return { ...prevFieldLabels, questions: updatedQuestions };
    });
  };

  const handleSave = async () => {
    const payload = fieldLabels?.questions?.map((question) => ({
      id: question.id,
      fields: question.fields
        .filter((field) => field.columnNumber !== 0)
        .map((field) => ({
          id: field?.id,
          userLabel: field?.userLabel || "",
          fieldType: field?.fieldType,
        })),
    }));

    try {
      if (Array.isArray(payload) && payload?.length > 0) {
        await updateCommonGroupFieldLabels(payload);
      }
    } catch (error) {
      console.error("Error updatiing CommonGroup FieldLabels:", error);
    } finally {
      handleCloseModal();
    }
  };

  useEffect(() => {
    const fetchCommonGroupFieldLabels = async () => {
      try {
        if (savedQuesGrp?.id) {
          const response = await commonGroupFieldLabels(savedQuesGrp?.id);
          setFieldLabels(response.data);
        }
      } catch (error) {
        console.error("Error fetching CommonGroup FieldLabels:", error);
      }
    };

    fetchCommonGroupFieldLabels();
  }, [savedQuesGrp]);

  const addDisplayRule = (field, level) => {
    navigate("/survey-builder");
    dispatch(
      setSurveyBuilderCurrentView({
        viewIndex: 11,
        source: level,
      })
    );
    dispatch(setSelectedRuleField(field));
  };

  const columns = useMemo(() => {
    return fieldLabels?.headings.map((heading) => {
      if (heading.sequence === 0) {
        return {
          accessorKey: `heading_${heading.sequence}`,
          header: heading.name,
          size: 250,
          Cell: ({ row }) => {
            const question = row.original;
            return <Typography>{question.label}</Typography>;
          },
        };
      } else {
        return {
          accessorKey: `heading_${heading.sequence}`,
          header: heading.name,
          size: 150,
          Cell: ({ row }) => {
            const question = row.original;
            const matchedField = question.fields.find(
              (field) => field.columnNumber === heading.sequence
            );

            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  size="small"
                  style={{ width: "100px", marginRight: "8px" }}
                  value={matchedField?.userLabel || ""}
                  onChange={(e) =>
                    handleChange(e, question.id, matchedField?.columnNumber)
                  }
                />
                {(matchedField?.fieldType?.name === "Auto Calc Total Field" ||
                  matchedField?.fieldType?.name === "Numeric Field") && (
                  <Checkbox
                    checked={
                      matchedField?.fieldType?.name === "Auto Calc Total Field"
                    }
                    onChange={(e) =>
                      handleChange(
                        e,
                        question.id,
                        matchedField?.columnNumber,
                        true
                      )
                    }
                  />
                )}
                <Tooltip
                  title={
                    matchedField?.rule ? "Edit field rule" : "Add field rule"
                  }
                  arrow
                >
                  <EditNoteOutlinedIcon
                    sx={{
                      cursor: "pointer",
                      width: 20,
                      height: 20,
                      color: matchedField?.rule
                        ? theme.palette.blue.main
                        : theme.palette.blue.light,
                    }}
                    onClick={() => {
                      dispatch(setSelectedRuleField({}));
                      const ruleId = matchedField?.rule?.id;
                      addDisplayRule(
                        matchedField,
                        ruleId
                          ? `addFieldLevelDR__${ruleId}`
                          : "addFieldLevelDR"
                      );
                    }}
                  />
                </Tooltip>
              </div>
            );
          },
        };
      }
    });
  }, [fieldLabels]);

  const data = useMemo(() => {
    return fieldLabels.questions;
  }, [fieldLabels]);

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { density: "compact" },
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnActions: false,
    enableSorting: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enablePagination: false,
  });

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        maxHeight: "80%",
        bgcolor: "#fff",
        boxShadow: 24,
        borderRadius: 3,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="panelDiv">
        <Typography variant="label">Update Group User Labels</Typography>
      </div>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          padding: "16px 16px 0 16px",
        }}
      >
        <MaterialReactTable table={table} />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px",
          gap: "8px",
        }}
      >
        <Box>
          <Button variant="outlined" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={handleSave}>
            Save
          </Button>
        </Box>
        <Typography color="error">
          Checked fields will be auto-calculated as the sum of the fields above
          them in the same column.
        </Typography>
      </Box>
    </Box>
  );
};

export default UserlabelModal;
