import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Modal,
  Grid,
  Box,
  FormControl,
  Select,
  FormLabel,
  MenuItem,
  IconButton,
  TextField,
  FormControlLabel,
  InputLabel,
  RadioGroup,
} from "@mui/material";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import MuiAlert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import { Radio, Snackbar } from "@mui/material";

import Button from "../../../components/Common/Button";
import RuleHelpText from "../RuleHelpText";
import SurveyTree from "./SurveyTree";
import {
  selHistSelectedQues,
  setSurveyBuilderCurrentView,
  setSelectedQues,
  triggerSidebarRefresh,
} from "../../../pages/Survey/Survey.slice";
import theme from "../../../theme";
import {
  initNewVrData,
  logicalOperators,
  mathOperators,
  numericFunctions,
  alphaFunctions,
  panelAttribuites,
  vLevel1,
  vLevel2,
  vLevel3,
  isEditorFieldsCase,
  findLevel,
  findRedirectVrListPage,
} from "./RuleConstant";
import Tooltip from "../../../components/Common/Tooltip";
import { getTextFromHTML } from "../../../Utility/constants";
import {
  getRule,
  saveRule,
  ruleFieldLabels,
  updateFieldUserLabel,
  ruleModeExceptions,
  ruleEditorSurveys,
  nextComplexRule,
  deleteRule,
  saveDataFilterRule,
} from "../../Survey/Survey.service";

const ManageRules = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const states = useSelector((state) => state?.survey?.states);
  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );
  const surveyId = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData?.survey?.id
  );
  const surveyName = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData?.survey?.name
  );
  const selectedSecId = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedSec?.section?.id
  );
  const selectedPageId = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedPage?.page?.id
  );
  const selectedQuesGrpId = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedQuesGrp?.id
  );
  const selectedRuleField = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedRuleField?.id
  );
  const selectedQuesData = useSelector(
    (state) => state?.survey?.surveyBuilderData.selectedQues
  );
  const selectedHistQuesData = useSelector(
    (state) => state?.survey?.surveyBuilderData.histSelectedQues
  );
  const currentSource = useSelector(
    (state) => state?.survey?.surveyBuilderData.surveyBuilderCurrentView.source
  );
  const showDataFilterRule = useSelector(
    (state) =>
      state?.survey?.surveyBuilderData.surveyBuilderCurrentView
        .showDataFilterRule
  );
  const datafilterRecord = useSelector(
    (state) =>
      state?.survey?.surveyBuilderData.surveyBuilderCurrentView.datafilterRecord
  );

  const [openVrHelpModal, setOpenVrHelpModal] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [fieldNameUpdateMsg, setFieldNameUpdateMsg] = useState(null);
  const [ruleEditorLevel, setRuleEditorLevel] = useState(null);
  const [formData, setFormData] = useState(initNewVrData);
  const [fieldName, setFieldName] = useState(null);
  const [fieldVal, setFieldVal] = useState(null);

  const [userText, setUserText] = useState([]);
  const [expression, setExpression] = useState([]);
  const [ruleId, setRuleId] = useState(null);
  const [ruleSurveyList, setRuleSurveyList] = useState([]);
  const [selFieldSurveyId, setSelFieldSurveyId] = useState(null);
  const [surveyIds, setSurveyIds] = useState([]);

  const [legendTitle, setLegendTitle] = useState("FIELD OPERANDS");
  const [questionFields, setQuestionFields] = useState([]);
  const [questionFieldName, setQuestionFieldName] = useState(null);
  const [questionFieldId, setQuestionFieldId] = useState(null);
  const [questionFieldValues, setQuestionFieldValues] = useState([]);
  const [selectedFieldValue, setSelectedFieldValue] = useState(null);
  const [showOperand, setShowOperand] = useState(false);
  const [refNumber, setRefNumber] = useState("(NEW)");

  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  useEffect(() => {
    const updatedData = {
      ...initNewVrData,
      requiredAction: ruleEditorLevel === "addFieldLevelDR" ? "H" : "Fix",
    };
    setFormData(updatedData);
  }, [ruleEditorLevel]);

  useEffect(() => {
    if (currentSource) {
      if (currentSource.includes("__")) {
        const rowSoure = currentSource.split("__");
        const rowId = rowSoure[1];
        const rowName = rowSoure[0];
        setRuleEditorLevel(rowName);
        setRuleId(rowId);
      } else {
        setRuleEditorLevel(currentSource);
      }
    }
  }, [currentSource]);

  useEffect(() => {
    if (datafilterRecord && datafilterRecord.ruleId !== undefined) {
      setRuleEditorLevel("SurveyLevel");
      setRuleId(datafilterRecord.ruleId);
    }
  }, [datafilterRecord]);

  useEffect(() => {
    const fetchRuleEditorSurveys = async () => {
      try {
        const response = await ruleEditorSurveys(surveyId);
        if (response?.data) {
          let surveyList = [];
          if (response.data.length > 1) {
            response.data.forEach((element) => {
              const item = {
                id: element.survey.id,
                name: element.survey.name,
                fieldPrefix: element.fieldPrefix,
              };
              surveyList.push(item);
            });

            setSelFieldSurveyId(response.data[0].survey.id);
          }

          setRuleSurveyList(surveyList);
        }
      } catch (error) {
        console.error(
          "Error fetching survey list for validation rule editor:",
          error
        );
      }
    };
    if (surveyId) {
      fetchRuleEditorSurveys();
    }
  }, [surveyId]);

  useEffect(() => {
    const fetchRuleData = async () => {
      setUserText([]);
      setExpression([]);
      try {
        const response = await getRule(ruleId);

        setFormData((prevData) => ({
          ...prevData,
          id: response.data.id,
          userText: response.data.userText,
          expression: response.data.expression,
          errorText: response.data.errorText,
          ruleType: response.data.ruleType,
          requiredAction: response.data.requiredAction,
          sequence: response.data.sequence,
        }));
        if (response.data.userText) {
          let str = [];
          let ruleusertextStr = response.data.userText.trim();
          str.push(ruleusertextStr.split(" "));
          setUserText(str);
        }
        if (response.data.expression) {
          let str = [];
          let ruleuserexressionStr = response.data.expression.trim();
          str.push(ruleuserexressionStr.split(" "));
          setExpression(str);
        }
        setRefNumber("(REF. # " + response.data.id + ")");
      } catch (error) {
        console.error("Error fetching rule data:", error);
      }
    };

    const fetchRuleModeExceptions = async () => {
      try {
        const response = await ruleModeExceptions(ruleId);
        if (response?.data) {
          setFormData((prevData) => ({
            ...prevData,
            ruleModeExceptionStateIds: response.data,
          }));
        }
      } catch (error) {
        console.error("Error fetching rule mode exceptions:", error);
      }
    };
    const fetchRuleFieldLabels = async () => {
      try {
        const response = await ruleFieldLabels(ruleId);
        if (response?.data) {
          setFormData((prevData) => ({
            ...prevData,
            fieldLabels: response?.data,
          }));
        }
      } catch (error) {
        console.error("Error fetching field labels:", error);
      }
    };
    if (ruleId) {
      fetchRuleData();
      fetchRuleFieldLabels();
      fetchRuleModeExceptions();
    }
  }, [ruleId]);

  const handleOpenVrHelpModal = () => {
    setOpenVrHelpModal(true);
  };
  const handleCloseVrHelpModal = () => {
    setOpenVrHelpModal(false);
  };

  const redirectListingPage = () => {
    if (ruleEditorLevel === "addFieldLevelDR") {
      navigate("/add-question", { state: { openLabelModal: true } });
    }
    dispatch(triggerSidebarRefresh());
    dispatch(setSelectedQues({}));
    dispatch(selHistSelectedQues({}));
    const vrPage = findRedirectVrListPage(ruleEditorLevel);
    dispatch(
      setSurveyBuilderCurrentView({
        viewIndex: vrPage.viewIndex,
        source: vrPage.source,
      })
    );
  };
  const handleSubmit = async (action) => {
    setErrMsg("Saving rule…");
    if (formData["expression"] === "") {
      setErrMsg("Rule expression must be specified.");
    } else {
      const selFieldLabels = formData["fieldLabels"]?.trim();
      let payload = {
        expression: formData["expression"]?.trim(),
        userText: formData["userText"],
        fieldLabels: selFieldLabels,
        errorText: formData["errorText"]?.trim(),
        ruleType:
          ruleEditorLevel === "addPageLevelDR" ||
          ruleEditorLevel === "addQuesGrpLevelDR" ||
          ruleEditorLevel === "addFieldLevelDR"
            ? "Complex Rule"
            : showDataFilterRule
            ? "Data Filter Rule"
            : formData["ruleType"],
        requiredAction:
          ruleEditorLevel === "addQuesGrpLevelDR"
            ? "Hide Question"
            : ruleEditorLevel === "addPageLevelDR"
            ? "skip"
            : formData["requiredAction"],
        fieldSurveyIds: surveyIds.length === 0 ? [surveyId] : surveyIds,
        ruleModeExceptionStateIds:
          formData["ruleModeExceptionStateIds"]?.length > 0
            ? formData["ruleModeExceptionStateIds"]?.join()
            : null,
      };
      let editorLevel = findLevel(
        ruleEditorLevel,
        surveyId,
        selectedRuleField,
        selectedQuesGrpId,
        selectedPageId,
        selectedSecId
      );
      payload = { ...payload, ...editorLevel };
      let entry = {};
      if (ruleId) {
        entry = {
          id: ruleId,
          sequence: formData["sequence"],
          modifiedBy: userName,
        };
      } else {
        entry = {
          createdBy: userName,
        };
      }
      payload = { ...payload, ...entry };
      if (showDataFilterRule) {
        const filterRulePayload = {
          dataFilterId: datafilterRecord.id,
          ruleSaveRequest: payload,
        };
        try {
          let response = await saveDataFilterRule(filterRulePayload);

          if (response?.status === 200 && response?.data?.id) {
            setErrMsg(null);
            setSuccessSnackbarOpen(true);
            setAlertMsg("Rule saved successfully");
            setAlertSeverity("success");
            setRefNumber("");
            redirectDataFilters();
          } else if (response?.data?.detail) {
            setErrMsg(response?.data?.detail);
          }
        } catch (error) {
          console.log("Error deleting survey data.", error);
          return null;
        }
      } else {
        try {
          let response = await saveRule(payload);

          if (response?.status === 200 && response?.data?.id) {
            setErrMsg(null);
            setSuccessSnackbarOpen(true);
            setAlertMsg("Rule saved successfully");
            setAlertSeverity("success");
            setRefNumber("");

            setSurveyIds([]);
            setUserText([]);
            setExpression([]);

            setTimeout(() => {
              if (action === "done") {
                redirectListingPage();
              } else if (action === "save") {
                if (
                  ruleEditorLevel === "addPageLevelDR" ||
                  ruleEditorLevel === "addQuesGrpLevelDR" ||
                  ruleEditorLevel === "addFieldLevelDR"
                ) {
                  redirectListingPage();
                } else showNextComplexRule(response?.data?.id);
              }
            }, 1000);
          } else if (response?.data?.detail) {
            setErrMsg(response?.data?.detail);
          }
        } catch (error) {
          console.log("Error deleting survey data.", error);
          return null;
        }
      }
    }
  };

  const redirectDataFilters = () => {
    setSurveyIds([]);
    setUserText([]);
    setExpression([]);
    dispatch(setSelectedQues({}));
    dispatch(selHistSelectedQues({}));
    navigate("/file-mgr", {
      state: {
        surveyId: surveyId,
        surveyName: surveyName,
        visitDataFilterRule: true,
      },
    });
  };

  const handleDeleteDisplayRule = async () => {
    try {
      const response = await deleteRule(ruleId);
      if (response?.status === 200 && response?.data === true) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Rule deleted successfully");
        setAlertSeverity("success");
        setTimeout(() => {
          redirectListingPage();
        }, 1000);
      }
    } catch (error) {
      console.log("Error occured deleting display rule", error);
      return null;
    } finally {
      dispatch(triggerSidebarRefresh());
    }
  };

  const handleDeleteDataFilterRule = async () => {
    try {
      const response = await deleteRule(datafilterRecord.ruleId);
      if (response?.status === 200 && response?.data === true) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Rule deleted successfully");
        setAlertSeverity("success");
        setTimeout(() => {
          redirectDataFilters();
        }, 1000);
      }
    } catch (error) {
      console.log("Error occured deleting page rule", error);
      return null;
    }
  };
  const showNextComplexRule = async (ruleId) => {
    try {
      let response = await nextComplexRule(ruleId);

      if (response?.status === 200 && response?.data === "") {
        setFormData(initNewVrData);
        setRefNumber("(NEW)");

        const lastfield = questionFields;
        if (lastfield) {
          let prefixRows = [];
          prefixRows = lastfield.filter((row) => row.fieldPrefix === "H_");
          if (prefixRows.length > 0) {
            setLegendTitle("FIELD OPERANDS");
            setQuestionFieldName(null);
            setQuestionFieldId(null);
            setQuestionFields([]);
            setQuestionFieldValues([]);
            setSelectedFieldValue(null);
            setShowOperand(false);
          }
        }
      } else if (response?.data.id) {
        setRuleId(response?.data.id);
      }
    } catch (error) {
      console.log("Error getting next complex rule", error);
      return null;
    }
  };
  const handleAddRule = (target = "", method = "add", separator = "") => {
    if (method === "add") {
      const data = userText;
      let endSeparator = "";
      let newEntry = "";
      if (separator) {
        if (separator === "(") endSeparator = " " + separator;
        else if (separator === '"') endSeparator = "" + separator;
      }
      let startSeparator = "";
      if (target === "alphaconst") startSeparator = '"';
      else if (target === "var1" || target === "var2" || target === "var3") {
        startSeparator = separator;
      }
      newEntry = startSeparator + formData[target] + endSeparator;
      data.push(newEntry);
      setUserText(data);

      const newtext = data.join(" ");
      setFormData((prevData) => ({
        ...prevData,
        userText: newtext,
      }));

      const data2 = expression;
      data2.push(newEntry);
      setExpression(data2);

      const newtext1 = data2.join(" ");
      setFormData((prevData) => ({
        ...prevData,
        expression: newtext1,
      }));
    } else if (method === "clearAll") {
      setUserText([]);
      setExpression([]);

      const newtext = "";
      setFormData((prevData) => ({
        ...prevData,
        userText: newtext,
        expression: newtext,
        fieldLabels: newtext,
      }));
    } else if (method === "clearLast") {
      const data = userText;
      data.pop();
      const newtextline = data.join(" ");
      setFormData((prevData) => ({
        ...prevData,
        userText: newtextline,
      }));
      setUserText(data);

      let data2 = expression;
      const poppedValue = data2.pop();
      const newtextline2 = data2.join(" ");
      setFormData((prevData) => ({
        ...prevData,
        expression: newtextline2,
      }));
      setExpression(data2);

      if (poppedValue) {
        const valueWithoutTilde = poppedValue.includes("~fv")
          ? poppedValue.trim().slice(1)
          : poppedValue;

        const oldFieldLabels = formData["fieldLabels"] || "";
        const fieldLabelsArray = oldFieldLabels
          .split(",")
          .filter((label) => label !== "");

        if (
          fieldLabelsArray.some((item) => item.trim() === valueWithoutTilde)
        ) {
          const updatedFieldLabels = fieldLabelsArray.filter(
            (item) => item.trim() !== valueWithoutTilde
          );

          const fieldLabelsString = updatedFieldLabels.join(",");
          const finalFieldLabels =
            updatedFieldLabels.length === 1
              ? fieldLabelsString + ","
              : fieldLabelsString;

          const cleanedExp = expression.map((label) =>
            label.trim().startsWith("~fv")
              ? label.trim().slice(1)
              : label.trim()
          );

          const fieldLabelExists = cleanedExp.includes(valueWithoutTilde);

          if (!fieldLabelExists) {
            setFormData((prevData) => ({
              ...prevData,
              fieldLabels: finalFieldLabels,
            }));
          }
        }
      }
    }
  };

  useEffect(() => {
    // console.log(formData);
  }, [formData]);

  const handleFieldLabelValue = async () => {
    setFieldNameUpdateMsg(null);
    const selField = questionFields?.find(
      (obj) => obj.fieldLabel === questionFieldId
    );
    try {
      const payload = {
        surveyId: selField.surveyId,
        fieldId: selField.id,
        userLabel: questionFieldName,
      };
      const response = await updateFieldUserLabel(payload);
      if (response?.status === 200 && response?.data === true) {
        // dispatch(triggerSidebarRefresh());
      } else if (response?.data?.detail) {
        setFieldNameUpdateMsg(response?.data?.detail);
      }
    } catch (error) {
      console.error("Error updating field user label:", error);
    }
  };
  const handleQfieldNameChange = (e) => {
    const { value } = e.target;
    setQuestionFieldName(value);
  };

  useEffect(() => {
    const detectOperands = () => {
      dispatch(selHistSelectedQues({}));
      const legendText =
        "FIELD OPERANDS FOR QUESTION " +
        (selectedQuesData.label ? selectedQuesData.label.toUpperCase() : "") +
        getTextFromHTML(selectedQuesData.name.toUpperCase());
      setLegendTitle(legendText);
      let row = [];
      selectedQuesData.fields.forEach((element) => {
        row.push({
          fieldLabel: element.fieldLabel,
          name: element.fieldType.name,
          userLabel: element?.userLabel ? element?.userLabel : "",
          id: element.id,
          fieldPrefix: "",
          surveyId: surveyId,
        });
      });
      setQuestionFields(row);
      if (selectedQuesData.fields.length > 0) {
        setQuestionFieldName(selectedQuesData.fields[0].userLabel);
        setQuestionFieldId(selectedQuesData.fields[0].fieldLabel);
      } else {
        setQuestionFieldId(null);
        setQuestionFieldName(null);
      }
      if (
        selectedQuesData.fields.length > 0 &&
        selectedQuesData.fields[0].fieldValues &&
        selectedQuesData.fields[0].fieldValues.length > 0
      ) {
        setSelectedFieldValue(selectedQuesData.fields[0].fieldValues[0].id);
        let values = [];
        selectedQuesData.fields[0].fieldValues.forEach((element) => {
          values.push({
            id: element.id,
            name: element.name ? element.name : element.allowableValue,
            allowableValue: element.allowableValue,
          });
        });
        setQuestionFieldValues(values);
      } else {
        setQuestionFieldValues([]);
        setSelectedFieldValue(null);
      }

      setShowOperand(true);
    };

    if (Object.keys(selectedQuesData).length > 0) {
      detectOperands();
    }
  }, [selectedQuesData]);
  useEffect(() => {
    const detectHistOperands = () => {
      const legendText =
        "FIELD OPERANDS FOR QUESTION " +
        (selectedHistQuesData.label
          ? selectedHistQuesData.label.toUpperCase()
          : "") +
        getTextFromHTML(selectedHistQuesData.name.toUpperCase());
      setLegendTitle(legendText);

      const selRecord = ruleSurveyList.find(
        (obj) => obj.id === selFieldSurveyId
      );
      let row = [];
      selectedHistQuesData.fields.forEach((element) => {
        row.push({
          fieldLabel: element.fieldLabel,
          name: element.fieldType.name,
          userLabel: element?.userLabel ? element?.userLabel : "",
          id: element.id,
          fieldPrefix: selRecord.fieldPrefix,
          surveyId: selFieldSurveyId,
        });
      });
      // console.log(row);
      setQuestionFields(row);
      if (selectedHistQuesData.fields.length > 0) {
        setQuestionFieldName(selectedHistQuesData.fields[0].userLabel);
        setQuestionFieldId(selectedHistQuesData.fields[0].fieldLabel);
      } else {
        setQuestionFieldId(null);
        setQuestionFieldName(null);
      }
      if (
        selectedHistQuesData.fields.length > 0 &&
        selectedHistQuesData.fields[0].fieldValues &&
        selectedHistQuesData.fields[0].fieldValues.length > 0
      ) {
        setSelectedFieldValue(selectedHistQuesData.fields[0].fieldValues[0].id);
        let values = [];
        selectedHistQuesData.fields[0].fieldValues.forEach((element) => {
          values.push({
            id: element.id,
            name: element.name ? element.name : element.allowableValue,
            allowableValue: element.allowableValue,
          });
        });
        setQuestionFieldValues(values);
      } else {
        setQuestionFieldValues([]);
        setSelectedFieldValue(null);
      }
      setShowOperand(true);
      setSelFieldSurveyId(surveyId);
      dispatch(selHistSelectedQues({}));
    };

    if (Object.keys(selectedHistQuesData).length > 0) {
      detectHistOperands();
    }
  }, [selectedHistQuesData]);
  useEffect(() => {
    const detectChanges = () => {
      if (fieldName === "numeric" || fieldName === "alphafun") {
        handleAddRule(fieldName, "add", "(");
      } else if (
        fieldName === "var1" ||
        fieldName === "var2" ||
        fieldName === "var3"
      ) {
        handleAddRule(fieldName, "add", "~");
      } else {
        handleAddRule(fieldName);
      }
    };
    if (fieldName || fieldVal) {
      detectChanges();
    }
  }, [fieldName, fieldVal]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const isAllowedCase = isEditorFieldsCase(name);
    if (isAllowedCase) {
      setFieldName(name);
      setFieldVal(value);
    }
    if (name === "expression") {
      const data = value.split(" ");
      setExpression(data);
    }
  };
  const handleAddFields = (criteria) => {
    const oldUserText = formData["userText"];
    const oldExpression = formData["expression"];
    const oldFieldLabels = formData["fieldLabels"];

    const selRecord = questionFields?.find(
      (obj) => obj.fieldLabel === questionFieldId
    );
    let existingSurveyId = surveyIds;
    if (existingSurveyId.indexOf(selRecord.surveyId) === -1) {
      existingSurveyId.push(selRecord.surveyId);
    }
    setSurveyIds(existingSurveyId);
    let fieldPref = "";
    if (selRecord.fieldPrefix !== "") {
      fieldPref = selRecord.fieldPrefix;
    }

    if (criteria === "fields") {
      setFormData((prevData) => {
        const fieldLabelsArray = oldFieldLabels
          .split(",")
          .map((label) => label.trim())
          .filter((label) => label !== "");

        if (!fieldLabelsArray.includes(questionFieldId)) {
          return {
            ...prevData,
            userText: oldUserText + " " + fieldPref + questionFieldName,
            expression: oldExpression + " ~" + fieldPref + questionFieldId,
            fieldLabels:
              oldFieldLabels + " " + fieldPref + questionFieldId + ",",
          };
        }

        return {
          ...prevData,
          userText: oldUserText + " " + fieldPref + questionFieldName,
          expression: oldExpression + " ~" + fieldPref + questionFieldId,
          fieldLabels: oldFieldLabels,
        };
      });

      let data = userText;
      const newEntry = fieldPref + questionFieldName;
      data.push(newEntry);
      setUserText(data);

      let data1 = expression;
      const newEntry1 = " ~" + fieldPref + questionFieldId;
      data1.push(newEntry1);
      setExpression(data1);
    } else if (criteria === "fieldvalue") {
      const oldUserText = formData["userText"];
      const oldExpression = formData["expression"];
      const selField = questionFieldValues?.find(
        (obj) => obj.id === selectedFieldValue
      );

      setFormData((prevData) => ({
        ...prevData,
        userText:
          oldUserText +
          " " +
          fieldPref +
          JSON.stringify(selField.allowableValue),
        expression:
          oldExpression +
          " " +
          fieldPref +
          JSON.stringify(selField.allowableValue),
      }));

      let data = userText;
      const newEntry = fieldPref + JSON.stringify(selField.allowableValue);
      data.push(newEntry);
      setUserText(data);

      let data1 = expression;
      const newEntry1 =
        " " + fieldPref + JSON.stringify(selField.allowableValue);
      data1.push(newEntry1);
      setExpression(data1);
    }
  };
  const handleFieldValuesChange = (e) => {
    const { value } = e.target;
    setSelectedFieldValue(value);
  };
  const handleFieldSurveyId = (e) => {
    const { value } = e.target;
    if (value !== surveyId) {
      setSelFieldSurveyId(value);
      setShowSurveyModal(true);
    }
  };

  const handleCloseSurveyModal = () => {
    setSelFieldSurveyId(surveyId);
    setShowSurveyModal(false);
  };
  const handleSurveyTreeCloseModal = () => {
    setShowSurveyModal(false);
  };
  const handleFieldChange = (e) => {
    const { value } = e.target;
    const selField = questionFields?.find((obj) => obj.fieldLabel === value);
    setQuestionFieldId(value);
    setQuestionFieldName(selField.userLabel);

    if (selField.fieldValues && selField.fieldValues.length > 0) {
      setSelectedFieldValue(selField.fieldValues[0].id);
      let values = [];
      selField.fieldValues.forEach((element) => {
        values.push({
          id: element.id,
          name: element.name ? element.name : element.allowableValue,
          allowableValue: element.allowableValue,
        });
      });
      setQuestionFieldValues(values);
    } else {
      setQuestionFieldValues([]);
      setSelectedFieldValue(null);
    }
  };

  return (
    <>
      {ruleSurveyList && ruleSurveyList.length > 1 && (
        <>
          <Grid item xs={4}>
            <FormControl sx={{ minWidth: "320px", maxWidth: "320px" }}>
              <InputLabel className="inputLabel">Survey</InputLabel>
              <Select
                size="small"
                name="fields"
                value={selFieldSurveyId}
                onChange={(e) => handleFieldSurveyId(e)}
                label="Survey"
              >
                {ruleSurveyList.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {getTextFromHTML(item.name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Divider
            color={theme.palette.blue.main}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          />
        </>
      )}
      <Box component="fieldset">
        <legend>
          <b style={{ color: theme.palette.primary.main, fontSize: "12px" }}>
            {legendTitle}
          </b>
        </legend>
        {!showOperand && (
          <Typography variant="body2">
            PLEASE SELECT A QUESTION FOR FIELD OPERANDS.
          </Typography>
        )}
        {showOperand && (
          <>
            <Grid container rowSpacing={2}>
              {questionFields.length === 0 && (
                <Grid item xs={4}>
                  <Typography
                    color="error"
                    variant="body1"
                    sx={{ fontWeight: "bold", fontSize: "12px" }}
                  >
                    No fields exist for this question.
                  </Typography>
                </Grid>
              )}
              {questionFields.length > 0 && (
                <Grid
                  item
                  xs={12}
                  container
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Grid item xs={3} xl={2}>
                    <FormControl>
                      <InputLabel className="inputLabel">FIELDS</InputLabel>
                      <Select
                        size="small"
                        name="fields"
                        value={questionFieldId}
                        onChange={(e) => handleFieldChange(e)}
                        style={{ minWidth: "110px", maxWidth: "280px" }}
                        label="FIELDS"
                      >
                        {questionFields.map((item, index) => (
                          <MenuItem key={index} value={item.fieldLabel}>
                            {item.userLabel} ({item.name})
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} xl={1} sx={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleAddFields("fields")}
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item xs={2} xl={1}>
                    <TextField
                      name="questionFieldid"
                      size="small"
                      value={questionFieldId}
                      variant="outlined"
                      label="FIELD ID"
                      style={{ width: 110 }}
                    />
                  </Grid>
                  <Grid item xs={2} xl={2}>
                    <TextField
                      name="questionFieldname"
                      size="small"
                      value={questionFieldName}
                      variant="outlined"
                      label="FIELD NAME"
                      onChange={(e) => handleQfieldNameChange(e)}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleFieldLabelValue()}
                    >
                      Save
                    </Button>
                  </Grid>
                  {fieldNameUpdateMsg && (
                    <Grid item xs={2} xl={4}>
                      <Typography
                        color="error"
                        variant="body1"
                        sx={{ fontWeight: "bold", fontSize: "12px" }}
                      >
                        {fieldNameUpdateMsg}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
              {questionFieldValues.length > 0 && (
                <Grid
                  item
                  xs={12}
                  container
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Grid item xs={4}>
                    <FormControl>
                      <InputLabel className="inputLabel">
                        FIELD VALUE
                      </InputLabel>
                      <Select
                        style={{ minWidth: "320px", maxWidth: "330px" }}
                        size="small"
                        name="fieldvalue"
                        value={selectedFieldValue}
                        onChange={(e) => handleFieldValuesChange(e)}
                        label="FIELD VALUE"
                      >
                        {questionFieldValues.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {getTextFromHTML(item.name)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleAddFields("fieldvalue")}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Box>
      <Divider
        color={theme.palette.blue.main}
        style={{ marginTop: "10px", marginBottom: "10px" }}
      />
      <Box component="fieldset">
        <legend>
          <b style={{ color: theme.palette.primary.main, fontSize: "12px" }}>
            OPERATORS & FUNCTIONS & VARIABLES
          </b>
        </legend>
        <Grid container rowSpacing={1} alignItems="center">
          <Grid item xs={12} container>
            <Grid item xs={2}>
              <Typography variant="button">LOGICAL OPERATORS</Typography>
            </Grid>
            <Grid item xs={2}>
              <FormControl>
                <Select
                  style={{ width: 80 }}
                  size="small"
                  name="logical"
                  fullWidth
                  value={formData["logical"]}
                  onChange={(e) => handleChange(e)}
                >
                  {logicalOperators.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                size="small"
                color="primary"
                onClick={() => handleAddRule("logical", "add")}
              >
                <AddCircleOutlinedIcon
                  color="primary"
                  className="pointerLink"
                ></AddCircleOutlinedIcon>
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="button">MATH. OPERATORS </Typography>
            </Grid>
            <Grid item xs={2}>
              <FormControl>
                <Select
                  style={{ width: 80 }}
                  size="small"
                  name="mathematical"
                  value={formData["mathematical"]}
                  onChange={handleChange}
                >
                  {mathOperators.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                size="small"
                color="primary"
                onClick={() => handleAddRule("mathematical", "add")}
              >
                <AddCircleOutlinedIcon
                  color="primary"
                  className="pointerLink"
                ></AddCircleOutlinedIcon>
              </IconButton>
            </Grid>

            <Grid item xs={2} sx={{ alignItems: "center" }}>
              <Typography variant="button">NUMERIC FUNCTIONS</Typography>
            </Grid>
            <Grid item xs={2}>
              <FormControl>
                <Select
                  size="small"
                  name="numeric"
                  value={formData["numeric"]}
                  fullWidth
                  onChange={handleChange}
                  style={{ minWidth: "150px" }}
                >
                  {numericFunctions.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                size="small"
                color="primary"
                onClick={() => handleAddRule("numeric", "add", "(")}
              >
                <AddCircleOutlinedIcon
                  color="primary"
                  className="pointerLink"
                ></AddCircleOutlinedIcon>
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Grid item xs={2}>
              <Typography variant="button">ALPHA FUNCTIONS</Typography>
            </Grid>
            <Grid item xs={2}>
              <FormControl>
                <Select
                  size="small"
                  name="alphafun"
                  value={formData["alphafun"]}
                  onChange={handleChange}
                >
                  {alphaFunctions.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                size="small"
                color="primary"
                onClick={() => handleAddRule("alphafun", "add", "(")}
              >
                <AddCircleOutlinedIcon
                  color="primary"
                  className="pointerLink"
                ></AddCircleOutlinedIcon>
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="button">NUMERIC CONSTANTS</Typography>
            </Grid>
            <Grid item xs={2}>
              <FormControl>
                <TextField
                  size="small"
                  name="numericconst"
                  value={formData["numericconst"]}
                  onChange={handleChange}
                  style={{ width: 80 }}
                />
              </FormControl>
              <IconButton
                size="small"
                color="primary"
                onClick={() => handleAddRule("numericconst", "add")}
              >
                <AddCircleOutlinedIcon
                  color="primary"
                  className="pointerLink"
                ></AddCircleOutlinedIcon>
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="button"> ALPHA CONSTANTS</Typography>
            </Grid>
            <Grid item xs={2}>
              <FormControl>
                <TextField
                  size="small"
                  name="alphaconst"
                  style={{ width: 100 }}
                  value={formData["alphaconst"]}
                  onChange={handleChange}
                />
              </FormControl>
              <IconButton
                size="small"
                color="primary"
                onClick={() => handleAddRule("alphaconst", "add", '"')}
              >
                <AddCircleOutlinedIcon
                  color="primary"
                  className="pointerLink"
                ></AddCircleOutlinedIcon>
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={2}>
              <Typography variant="button"> PANEL ATTRIBUTES</Typography>
            </Grid>
            <Grid item xs={2}>
              <FormControl>
                <Select
                  size="small"
                  name="panelattr"
                  fullWidth
                  style={{ width: 130 }}
                  value={formData["panelattr"]}
                  onChange={handleChange}
                >
                  {panelAttribuites.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                size="small"
                color="primary"
                onClick={() => handleAddRule("panelattr")}
              >
                <AddCircleOutlinedIcon
                  color="primary"
                  className="pointerLink"
                ></AddCircleOutlinedIcon>
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="button"> VARIABLES</Typography>
            </Grid>
            <Grid item xs={2}>
              <FormControl sx={{ minWidth: 100 }}>
                <Select
                  size="small"
                  name="var1"
                  value={formData["var1"]}
                  onChange={handleChange}
                >
                  {vLevel1.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                size="small"
                color="primary"
                onClick={() => handleAddRule("var1", "add", "~")}
              >
                <AddCircleOutlinedIcon
                  color="primary"
                  className="pointerLink"
                ></AddCircleOutlinedIcon>
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <FormControl sx={{ minWidth: 100 }}>
                <Select
                  size="small"
                  name="var2"
                  value={formData["var2"]}
                  onChange={handleChange}
                >
                  {vLevel2.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                size="small"
                color="primary"
                onClick={() => handleAddRule("var2", "add", "~")}
              >
                <AddCircleOutlinedIcon
                  color="primary"
                  className="pointerLink"
                ></AddCircleOutlinedIcon>
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <FormControl sx={{ minWidth: 100 }}>
                <Select
                  size="small"
                  name="var3"
                  value={formData["var3"]}
                  onChange={handleChange}
                >
                  {vLevel3.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton
                size="small"
                color="primary"
                onClick={() => handleAddRule("var3", "add", "~")}
              >
                <AddCircleOutlinedIcon
                  color="primary"
                  className="pointerLink"
                ></AddCircleOutlinedIcon>
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleAddRule("var4", "add")}
              >
                AND
              </Button>{" "}
              <Button
                variant="contained"
                size="small"
                onClick={() => handleAddRule("var5", "add")}
              >
                OR
              </Button>{" "}
              <Button
                variant="contained"
                size="small"
                onClick={() => handleAddRule("var6", "add")}
              >
                (
              </Button>{" "}
              <Button
                variant="contained"
                size="small"
                onClick={() => handleAddRule("var7", "add")}
              >
                )
              </Button>{" "}
              <Button
                variant="contained"
                size="small"
                onClick={() => handleAddRule("var8", "add")}
              >
                ,
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider
        color={theme.palette.blue.main}
        style={{ marginTop: "10px", marginBottom: "10px" }}
      />

      <Grid container rowSpacing={1}>
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={6}>
            <Typography variant="button">RULE {refNumber}</Typography>
            <TextField
              name="userText"
              multiline
              rows={3}
              fullWidth
              size="small"
              value={formData["userText"]}
              disabled={true}
              onChange={handleChange}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#302f2f",
                },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              style={{ marginLeft: "10px" }}
              size="small"
              onClick={() => handleAddRule("", "clearAll")}
            >
              Clear Entire Rule
            </Button>
            <Button
              style={{ marginLeft: "10px" }}
              variant="contained"
              size="small"
              onClick={() => handleAddRule("", "clearLast")}
            >
              Clear Last
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} container columnSpacing={3}>
          <Grid item xs={6}>
            <Typography variant="button">
              RULE [EXPERT MODE (USE ~ WITH FIELD IDS)]
            </Typography>
            <TextField
              name="expression"
              multiline
              rows={3}
              fullWidth
              size="small"
              value={formData["expression"]}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="button" paragraph>
              FIELDS [EXPERT MODE (ENTER FIELD IDS WITH NO ~ SEPARATED BY
              COMMAS)]
            </Typography>
            <TextField
              name="fieldLabels"
              fullWidth
              size="small"
              value={formData["fieldLabels"]}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} container columnSpacing={2}>
          <Grid item xs={6}>
            {ruleEditorLevel !== "addPageLevelDR" &&
              ruleEditorLevel !== "addQuesGrpLevelDR" && (
                <>
                  <Typography variant="button">ERROR MESSAGE</Typography>
                  <TextField
                    name="errorText"
                    multiline
                    rows={3}
                    fullWidth
                    size="small"
                    value={formData["errorText"]}
                    onChange={handleChange}
                  />
                </>
              )}
          </Grid>
          <Grid item xs={1}>
            {ruleEditorLevel !== "addPageLevelDR" &&
              ruleEditorLevel !== "addQuesGrpLevelDR" && (
                <>
                  <FormLabel
                    sx={{ fontSize: "14px" }}
                    id="requiredAction-group"
                  >
                    MODE
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="requiredAction-group"
                    name="requiredAction"
                    value={formData["requiredAction"]}
                    onChange={handleChange}
                  >
                    {ruleEditorLevel !== "addFieldLevelDR" && (
                      <>
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "14px",
                            },
                          }}
                          value="Fix"
                          control={<Radio size="small" />}
                          label="FIX"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "14px",
                            },
                          }}
                          value="Confirm"
                          control={<Radio size="small" />}
                          label="CONFIRM"
                        />
                      </>
                    )}
                    {ruleEditorLevel === "addFieldLevelDR" && (
                      <>
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "14px",
                            },
                          }}
                          value="H"
                          control={<Radio size="small" />}
                          label="HIDE"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "14px",
                            },
                          }}
                          value="D"
                          control={<Radio size="small" />}
                          label="DISABLE"
                        />
                        <FormControlLabel
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "14px",
                            },
                          }}
                          value="R"
                          control={<Radio size="small" />}
                          label="READ ONLY"
                        />
                      </>
                    )}
                  </RadioGroup>
                </>
              )}
          </Grid>
          <Grid item xs={3}>
            {ruleEditorLevel !== "addPageLevelDR" &&
              ruleEditorLevel !== "addQuesGrpLevelDR" &&
              ruleEditorLevel !== "addFieldLevelDR" && (
                <>
                  <Typography paragraph variant="string">
                    <span>ERROR MODE EXCEPTIONS</span>
                  </Typography>
                  <FormControl sx={{ minWidth: "350px", maxWidth: "350px" }}>
                    <InputLabel className="inputLabel">Choose State</InputLabel>
                    <Select
                      multiple
                      size="small"
                      variant="outlined"
                      name="ruleModeExceptionStateIds"
                      onChange={handleChange}
                      value={formData["ruleModeExceptionStateIds"]}
                      label="Choose State"
                    >
                      {states.map((state) => (
                        <MenuItem key={state.id} value={state.id}>
                          {state.longName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Validation rule editing instructions" arrow>
              <Typography variant="string" paragraph>
                <u
                  onClick={() => handleOpenVrHelpModal()}
                  className="pointerLink"
                >
                  HELP
                </u>
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        alignItems="center"
        style={{ marginTop: "20px" }}
      >
        <Grid item xs={5}>
          {errMsg && (
            <Typography
              color={errMsg === "Saving rule…" ? "info" : "error"}
              variant="body1"
              sx={{
                fontWeight: "bold",
                wordWrap: "break-word",
                marginRight: "15px",
                textAlign: "center",
              }}
            >
              {errMsg}
            </Typography>
          )}
        </Grid>
        {!showDataFilterRule && (
          <Grid item xs={7}>
            {ruleEditorLevel !== "addPageLevelDR" &&
              ruleEditorLevel !== "addQuesGrpLevelDR" &&
              ruleEditorLevel !== "addFieldLevelDR" && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => handleSubmit("done")}
                >
                  Done
                </Button>
              )}{" "}
            <Button
              variant="contained"
              size="small"
              onClick={() => handleSubmit("save")}
            >
              SAVE
            </Button>{" "}
            <Button
              variant="contained"
              size="small"
              onClick={() => redirectListingPage()}
            >
              Cancel
            </Button>{" "}
            {(ruleEditorLevel === "addPageLevelDR" ||
              ruleEditorLevel === "addQuesGrpLevelDR" ||
              ruleEditorLevel === "addFieldLevelDR") && (
              <Button
                variant="contained"
                size="small"
                onClick={() => handleDeleteDisplayRule()}
              >
                Delete Rule
              </Button>
            )}
          </Grid>
        )}
        {showDataFilterRule && (
          <Grid item xs={7}>
            <Button
              variant="contained"
              size="small"
              onClick={() => handleSubmit("save")}
            >
              Save
            </Button>{" "}
            {datafilterRecord.ruleId && (
              <Button
                variant="contained"
                size="small"
                onClick={() => handleDeleteDataFilterRule()}
              >
                Delete Rule
              </Button>
            )}{" "}
            <Button
              variant="contained"
              size="small"
              onClick={() => redirectDataFilters()}
            >
              Cancel
            </Button>
          </Grid>
        )}
        <Grid item xs={4}></Grid>
      </Grid>
      <Modal open={openVrHelpModal} onClose={handleCloseVrHelpModal}>
        <RuleHelpText handleClose={handleCloseVrHelpModal} />
      </Modal>

      <Modal open={showSurveyModal}>
        <SurveyTree
          selFieldSurveyId={selFieldSurveyId}
          handleClose={handleCloseSurveyModal}
          handleSurveyTreeClose={handleSurveyTreeCloseModal}
        />
      </Modal>

      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
    </>
  );
};
export default ManageRules;
