import React from "react";
import PiUpload from "./PiUpload";
import PiHome from "./PiHome";
import PiProgress from "./PiProgress";
import PiPastResults from "./PiPastResults";
import PiBatchSummary from "./PiBatchSummary";
import PiImportProgress from "./PiImportProgress";
import PiUploadProgress from "./PiUploadProgress";
import { useSelector } from "react-redux";

const renderView = (currentView) => {
  switch (currentView) {
    case 1:
      return <PiHome />;
    case 2:
      return <PiUpload />;
    case 3:
      return <PiProgress />;
    case 4:
      return <PiPastResults />;
    case 6:
      return <PiBatchSummary />;
    case 7:
      return <PiImportProgress />;
    case 8:
      return <PiUploadProgress />;
    default:
      return null;
  }
};
const PanelImport = () => {
  const currentView = useSelector((state) => state?.survey?.importCurrentView);

  return <>{renderView(currentView)}</>;
};

export default PanelImport;
